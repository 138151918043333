import '../../style/clients-page.css';
import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import ButtonAddElement from '../libs-common/button-add-element.js';
import ClientCard from './static-client.js';
import DBHandler from '../../database/database-handler-clients';

var createReactClass = require('create-react-class');
var React = require('react');

var StaticClientModule = createReactClass({
    getInitialState: function () {
        var _db_handler = new DBHandler();
        // _db_handler.addReloadMethod(this.reloadVariables);
        _db_handler.getClientsFromDB(this.onDbTransactionFinish);
        _db_handler.getClientTypesDB(this.onDbLoadClientTypes);
        let _clients = _db_handler.clientsObj();
        return {
            clients: _clients,
            children: Object.values(_clients.children),
            db_handler: _db_handler,
            clientTypes: []
        };
    },
    // reloadVariables: function () {
    //     if (this.dbHandler) {
    //         this.state.clients.children = this.dbHandler.clientsFromDB();
    //         this.setState({ newClientAdded: true });
    //     }
    // },
    addNewElement: function () {
        this.state.db_handler.addNewClient();
        this.setState({ newClientAdded: true });
    },
    onDbTransactionFinish: function (clients) {
        this.setState({
            clients: clients,
            children: Object.values(clients.children)
        });
    },
    onDbLoadClientTypes: function (types) {
        this.setState({
            clientTypes: types
        });
    },
    render: function () {
        return (
            <div class="static-clients-preview">
                {this.state.children.map(function (client) {
                    return (
                        <ClientCard client={client}
                            clients={this.state.clients}
                            dbHandler={this.state.db_handler}
                            clientTypes={this.state.clientTypes} />
                    );
                }.bind(this))}
                <div class="static-clients-card-wrapper">
                    <ButtonAddElement onClick={this.addNewElement} />
                </div>
            </div>
        );
    }
});

export default StaticClientModule;