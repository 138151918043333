import React from 'react';
import Cookies from 'js-cookie';
import AuthenticationManager from './authentication.js';

class AutomaticSessionHandler {
    constructor(_authentication_manager) {
        this.authentication_manager = _authentication_manager;
        this.running = false;
        this.renew = false;
        //https://github.com/js-cookie/js-cookie/wiki/Frequently-Asked-Questions#expire-cookies-in-less-than-a-day
        this.expire_secondes = 15 * 60 * 1000;
        this.cookie_expire_time = new Date(new Date().getTime() + this.expire_secondes);
    }

    Run() {
        Cookies.set('user_logged_in', true, {
            expires: this.cookie_expire_time
        });
        if (!this.running) {
            this.running = true;
            setInterval(() => {
                if (!Cookies.get('user_logged_in')) {
                    if (this.renew) {
                        Cookies.set('user_logged_in', true, {
                            expires: this.cookie_expire_time
                        });
                    }
                    else {
                        if (this.authentication_manager.isLoggedIn()) {
                            this.authentication_manager.logOut();
                        }
                    }
                }
                this.renew = false;
            }, this.expire_secondes);
        }
    }

    RenewSession() {
        if (!this.renew) {
            this.renew = true;
        }
    }

    ForceSessionCheck() {
        if (!Cookies.get('user_logged_in')) {
            this.authentication_manager.logOut();
        }
    }
}

export default AutomaticSessionHandler