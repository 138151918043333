import DBHandler from '../../database/database-handler-clients';
import MessageBox from '../libs-common/MessageBox';
import Client from '../model/client';
import enumRequestState from '../model/enums/request_state';

var createReactClass = require('create-react-class');
var React = require('react');


var DbPatcher = createReactClass({
    getInitialState: function () {
        return {
            selectedScript: "",
            showMessageBox: false,
            dbHandler: new DBHandler(),
            messageContent: "",
            deliveryId: "ns_kolorado_021",
        }
    },
    onClick: function (event) {
        const id = event.target.id;
        switch (id) {
            case "1stScript":
                this.setState({
                    selectedScript: "1stScript",
                    showMessageBox: true,
                    messageContent: "Nadam se da znas sta radis.",
                });
                break;
            case "2nsScript":
                this.setState({
                    selectedScript: "2nsScript",
                    showMessageBox: true,
                    messageContent: "Nadam se da znas sta radis.",
                });
                break;
            case "3thScript":
                this.setState({
                    selectedScript: "3thScript",
                    showMessageBox: true,
                    messageContent: "Nadam se da znas sta radis.",
                });
                break;
            case "4thScript":
                this.setState({
                    selectedScript: "4thScript",
                    showMessageBox: true,
                    messageContent: "Nadam se da znas sta radis.",
                });
                break;
            case "5thScript":
                this.setState({
                    selectedScript: "5thScript",
                    showMessageBox: true,
                    messageContent: "Nadam se da znas sta radis.",
                });
                break;
            case "6thScript":
                this.setState({
                    selectedScript: "6thScript",
                    showMessageBox: true,
                    messageContent: "Nadam se da znas sta radis.",
                });
                break;
            default:
                break;
        }
    },
    onInvokeConfirm: function () {
        switch (this.state.selectedScript) {
            case "1stScript":
                console.log("First script execution invoked!");
                this.firstScript(this.state.dbHandler);
                break;
            case "2nsScript":
                console.log("Second script execution invoked!");
                this.secondScript(this.state.dbHandler);
                break;
            case "3thScript":
                console.log("Third script execution invoked!");
                this.thirdScript(this.state.dbHandler);
                break;
            case "4thScript":
                console.log("Forth script execution invoked!");
                this.forthScript(this.state.dbHandler, this.state.deliveryId);
                break;
            case "5thScript":
                console.log("Fifth script execution invoked!");
                this.fifthScript(this.state.dbHandler);
                break;
            case "6thScript":
                console.log("Sixth script execution invoked!");
                this.sixthScript(this.state.dbHandler);
                break;

            default:
                console.log("Script is not defined.");
                break;
        }
        this.setState({
            selectedScript: "",
            showMessageBox: false,
        });
    },
    firstScript: async function (dbHandler) {
        console.log("Getting clients from DB started.");
        const clients = await dbHandler.getClientsFromDB();
        console.log("Getting clients from DB finished.");
        console.log("Clients count: " + Object.keys(clients.children).length);

        console.log("Getting client's menus started");
        Object.values(clients.children).forEach(await async function (client) {
            console.log("Getting menu for client: " + client.name);
            await dbHandler.getMenusFromClientDB(client);
        });
        console.log("Getting client's menus finished.");

        console.log("Updating client objects started.");
        const updatedMenus = {};
        Object.values(clients.children).forEach((client) => {
            console.log("Checking client: " + client.name);
            Object.values(client.menus).forEach(menu => {
                Object.values(menu.items).forEach(menuItem => {
                    Object.values(menuItem.items).forEach(menuSubItem => {
                        if (menuSubItem.price > 0
                            && !menuSubItem.usePrice) {
                            console.log("Sub item found!");
                            console.log("" + menu.name + " -> " + menuItem.name + " -> " + menuSubItem.name);
                            menuSubItem.usePrice = true;
                            updatedMenus[menu.id] = true;
                        }
                    });
                });
            });
        });
        console.log("Updating client objects finished.");
        console.log("Updating DB Started");
        if (Object.keys(updatedMenus).length > 0) {
            Object.values(clients.children).forEach((client) => {
                Object.values(client.menus).forEach(menu => {
                    if (updatedMenus[menu.id]) {
                        console.log("Updating menu: " + menu.name);
                        dbHandler.addOrUpdateMenuDB(client.id, menu);
                    }
                });
            });
        }
        else {
            console.log("There is no menus to update.");
        }
    },
    secondScript: async function (dbHandler) {
        console.log("Getting Orders in DONE state STARTED.");
        const orders = await dbHandler.getOrdersByStatesDB([enumRequestState.DONE], new Date(), null);
        const ordersToUpdate = {};
        console.log("Getting Orders in DONE state DONE.");
        console.log("Orders count: " + orders.length);
        console.log("Orders Processing STARTED.");
        for (let index = 0; index < orders.length; index++) {
            const orderObj = orders[index];
            if (orderObj && orderObj.cart && orderObj.cart.items && orderObj.cart.items.length > 0) {
                const newClient = new Client();
                newClient.id = orderObj.cart.client.id;
                console.log("Getting menus from client started");
                await dbHandler.getMenusFromClientDB(newClient, null);
                orderObj.cart.items.forEach(menuItem => {
                    const newSubItems = {};
                    Object.keys(menuItem.items).forEach((menuSubItemKey) => {
                        const menuSubItem = menuItem.items[menuSubItemKey];
                        if (menuSubItem.price > 0
                            && !menuSubItem.usePrice) {
                            console.log("Sub item found!");
                            console.log("" + menuItem.name + " -> " + menuSubItem.name);
                            menuSubItem.usePrice = true;
                            ordersToUpdate[orderObj.id] = orderObj;
                        }
                        if (menuSubItem.selected) {
                            newSubItems[menuSubItemKey] = menuSubItem;
                        }
                        if (!menuSubItem.isRadioButton) {
                            Object.values(newClient.menus).forEach(testMenu => {
                                Object.values(testMenu.items).forEach(testItem => {
                                    if (testItem.name === menuItem.name) {
                                        Object.values(testItem.items).forEach(testSubitem => {
                                            if (testSubitem.name === menuSubItem.name) {
                                                if (testSubitem.isRadioButton !== menuSubItem.isRadioButton) {
                                                    console.log("Wrong radio button property found.");
                                                    menuSubItem.isRadioButton = testSubitem.isRadioButton;
                                                    ordersToUpdate[orderObj.orderId] = orderObj;
                                                }
                                            }
                                        });
                                    }
                                });
                            });
                        }
                    });
                    menuItem.items = newSubItems;
                    ordersToUpdate[orderObj.orderId] = orderObj;
                });
            }
            const receiverAddress = orderObj.receiverAddress;
            const senderAddress = orderObj.senderAddress;
            const receiverAddressId = orderObj.receiverAddressId;
            const senderAddressId = orderObj.senderAddressId;
            if (receiverAddressId === senderAddressId) {
                console.log("Order with wrong address found! ID: " + orderObj.orderId);
                if (receiverAddress.id !== senderAddress.id) {
                    console.log("Only id is a problem.");
                    orderObj.receiverAddressId = receiverAddress.id;
                    orderObj.senderAddressId = senderAddress.id;
                    ordersToUpdate[orderObj.orderId] = orderObj;
                }
                else {
                    const userId = (orderObj.changesLog &&
                        orderObj.changesLog.length > 0 &&
                        orderObj.changesLog[0] &&
                        orderObj.changesLog[0].user &&
                        orderObj.changesLog[0].user.phone) ? orderObj.changesLog[0].user.phone : "";
                    if (userId) {
                        console.log("User id found : " + userId);
                        const user = await dbHandler.getUserByIdDB(userId, null);
                        if (!user) {
                            console.log("User could not be found in DB!");
                        }
                        else {
                            if (user.addresses && user.addresses.length > 0) {
                                const newAddressId = user.addresses[0];
                                const newAddress = await dbHandler.getAddressesByIdsDB([user.addresses[0]])
                                if (newAddress && newAddress[0]) {
                                    orderObj.receiverAddressId = newAddressId;
                                    orderObj.receiverAddress = newAddress[0];
                                    ordersToUpdate[orderObj.orderId] = orderObj;
                                    console.log("Order object updated with a new Address.");
                                }
                                else {
                                    console.log("Address not wound!");
                                    console.log("Address Id: " + newAddressId);
                                }
                            }
                            else {
                                console.log("User does not have any address.");
                            }
                        }
                    }
                    else {
                        console.log("User ID could not be found.");
                    }
                }

            }
        }
        console.log("Orders Fixing DONE.");
        console.log("Orders to update: " + Object.values(ordersToUpdate).length);
        console.log("Updating orders in DB STARTED.");
        console.log(ordersToUpdate);
        Object.values(ordersToUpdate).forEach(orderToUpdate => {
            dbHandler.updateOrderDB(orderToUpdate,
                (object) => {
                    if (object && object.orderId) {
                        console.log("Order updated in DB. ID: " + object.orderId);
                    }
                    else {
                        console.log("Error while updating order object.");
                    }
                });
        });
    },
    thirdScript: async function (dbHandler) {
        console.log("Getting all users STARTED.");
        const allUsers = await dbHandler.getAllUsers();
        console.log("Getting all users DONE.");
        console.log("User count: " + allUsers.length);
        console.log("Fixing users STARTED.");
        const usersToUpdate = {};
        for (let index = 0; index < allUsers.length; index++) {
            const userObj = allUsers[index];
            console.log("Checking user with id: " + userObj.phone);
            if (userObj.dateOfCreation === 0) {
                userObj.dateOfCreation = 1631692800000;
                console.log("Date of creation set to: " + 1631692800000);
                usersToUpdate[userObj.phone] = userObj;
            }
            if (userObj.dateOfCreation < 1633212000000) {
                console.log("User created before 3th October.");
                if (userObj.orders && userObj.orders.length > 0) {
                    const orders = await dbHandler.getOrdersByIdsDB(userObj.orders, null, null);
                    console.log("User have at least one order.");
                    if (orders && orders.length > 0) {
                        let makeItGold = false;
                        for (let x = 0; x < orders.length; x++) {
                            const order = orders[x];
                            if (order.dateOfCreation.getDate() < 1633212000000 && order.state === enumRequestState.DONE) {
                                makeItGold = true;
                            }
                        }
                        if (makeItGold) {
                            console.log("User can be GOLD");
                            if (!userObj.gold) {
                                userObj.gold = true;
                                console.log("User is now GOLD.");
                                usersToUpdate[userObj.phone] = userObj;
                            }
                        }
                        else {
                            if (userObj.gold) {
                                console.log("User is false gold.");
                            }
                        }
                    }
                }
            }
        }
        console.log("Fixing users FINISHED.");
        console.log("Users to update: " + Object.keys(usersToUpdate).length);
        if (Object.keys(usersToUpdate).length > 0) {
            console.log("Updating users in DB STARTED.");
            for (let index = 0; index < Object.values(usersToUpdate).length; index++) {
                const userToUpdate = Object.values(usersToUpdate)[index];
                await dbHandler.updateUserDB(userToUpdate, () => { console.log("User updated in db."); });
            };
            console.log("Updating users in DB FINISHED.");
        }

    },
    forthScript: async function (dbHandler, deliveryId) {
        console.log("Getting all clients from DB started.");
        const allClients = await dbHandler.getClientsFromDB();
        console.log("Getting clients from db done. Clients count: ", Object.values(allClients.children).length);
        Object.values(allClients.children).forEach(client => {
            if (!client.deliveryId) {
                console.log("Client without DeliveryID found. Client name: ", client.name);
                client.deliveryId = deliveryId;
                console.log("Updating client in DB.");
                dbHandler.addOrUpdateClientDB(client);
            }
        });

        console.log("Getting all Orders in all states from DB!");
        const allOrders = await dbHandler.getOrdersByStatesDB(
            [enumRequestState.CANCELED,
            enumRequestState.DONE,
            enumRequestState.IN_PROGRESS,
            enumRequestState.PENDING_FOR_CLIENT,
            enumRequestState.PENDING_FOR_DELIVERY],
            null, null);
        console.log("Getting all orders done. Order count: ", allOrders.length);

        allOrders.forEach(order => {
            if (!order.deliveryId) {
                console.log("Order without DeliveryId found. Id: ", order.orderId);

                order.deliveryId = deliveryId;
                console.log("Updating order in DB.");
                dbHandler.updateOrderDB(order, null, null, null);

            }
        });

        console.log("Script execution done.");
    },
    fifthScript: async function (dbHandler) {
        console.log("Getting all Orders in all states from DB!");
        const allOrders = await dbHandler.getOrdersByStatesDB(
            [enumRequestState.PENDING_FOR_CLIENT,
            enumRequestState.PENDING_FOR_DELIVERY],
            null, null);
        console.log("Getting all orders done. Order count: ", allOrders.length);

        allOrders.forEach(order => {
            console.log("Canceling order with ID: ", order.orderId);
            dbHandler.updateOrderDB(order, null, true, null);
        });

        console.log("Script execution done.");
    },
    sixthScript: async function (dbHandler) {
        console.log("Getting all Orders in states from DB!");
        const allOrders = await dbHandler.getOrdersByStatesDB(
            [enumRequestState.IN_PROGRESS,
            enumRequestState.DONE,
            enumRequestState.CANCELED],
            null, null);
        console.log("Getting orders done. Order count: ", allOrders.length);

        allOrders.forEach(order => {
            console.log("Setting seen to order: ", order.orderId);
            order.seen = true;
            dbHandler.updateOrderDB(order, null, null, null);
        });

        console.log("Script execution done.");
    },
    render: function () {
        return (
            <div>
                {this.state.showMessageBox ?
                    <MessageBox
                        title="Cekaj, stani pazi, vidi, evo.... gledaj!"
                        onClick={this.onInvokeConfirm}
                        content={this.state.messageContent}
                        showCancel={true}
                        onCancelClick={() => {
                            this.setState({
                                selectedScript: "",
                                showMessageBox: false,
                                messageContent: "",
                            });
                        }} /> : null}
                <h1>Ne diraj nista ako ne znas sta radis!</h1>

                <br />
                <p>
                    <h2>---------------------------------------------------------------------------------------------------------------------------------</h2>
                    <h5>Update all client menu items.</h5>
                    <br /> Find all menu items with enabled isRadioButton property and with price grater than 0 and update their usePrice property to true.
                    <br />
                    <button id="1stScript" onClick={this.onClick}>Do it!</button>
                    <h2>---------------------------------------------------------------------------------------------------------------------------------</h2>
                </p>
                <p>
                    <h2>---------------------------------------------------------------------------------------------------------------------------------</h2>
                    <h5>Update old orders in DONE state.</h5>
                    <br /> Find all orders in DONE state and fix Receiver address and address ID. Also fix Cart items with their use price property and <br />
                    isRadioButton property by comparing it with real client.
                    <br />
                    <button id="2nsScript" onClick={this.onClick}>Do it!</button>
                    <h2>---------------------------------------------------------------------------------------------------------------------------------</h2>
                </p>
                <p>
                    <h2>---------------------------------------------------------------------------------------------------------------------------------</h2>
                    <h5>Update all users.</h5>
                    <br /> Find all users in DB, make dateOfCreation property if it doesn't exists and make them gold if user is created before 3th October
                    <br /> and have at least one Order made before that date in DONE state.
                    <br />
                    <button id="3thScript" onClick={this.onClick}>Do it!</button>
                    <h2>---------------------------------------------------------------------------------------------------------------------------------</h2>
                </p>
                <p>
                    <h2>---------------------------------------------------------------------------------------------------------------------------------</h2>
                    <h5>Add provideid delivery ID to all Clients and all Orders without deliveryID.</h5>
                    <br />
                    <label for="deliveryId">Delivery ID:</label>
                    <input type="text" id="deliveryId" name="deliveryId" value={this.state.deliveryId}
                        onChange={(e) => { this.setState({ deliveryId: e.target.value }) }} /> <br />
                    <button id="4thScript" onClick={this.onClick}>Do it!</button>
                    <h2>---------------------------------------------------------------------------------------------------------------------------------</h2>
                </p>
                <p>
                    <h2>---------------------------------------------------------------------------------------------------------------------------------</h2>
                    <h5>Cancel all pending orders.</h5>
                    <br />
                    <button id="5thScript" onClick={this.onClick}>Do it!</button>
                    <h2>---------------------------------------------------------------------------------------------------------------------------------</h2>
                </p>
                <p>
                    <h2>---------------------------------------------------------------------------------------------------------------------------------</h2>
                    <h5>Set all orders Seen to true if order is not in beginning state..</h5>
                    <br />
                    <button id="6thScript" onClick={this.onClick}>Do it!</button>
                    <h2>---------------------------------------------------------------------------------------------------------------------------------</h2>
                </p>
            </div>
        );
    }
});


export default DbPatcher;