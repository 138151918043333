/* eslint-disable no-unused-vars */
/* eslint-disable require-jsdoc */
import Address from "./address";
import Image from "./image";
/**
 * Placeholder class for Address attributes.
 */
export class User {
    phone: string;
    name: string;
    addresses: Address[];
    favorites: string[];
    orders: string[];
    blocked: boolean;
    img: Image;
    email: string;
    informed: string[];
    selectedLanguage: string;
    giftPoints: number;
    doneSurveys: { [id: string]: boolean };
    agreedWithTermsAndConditions: boolean;
    settings: {};
    dateOfCreation: number;
    gold: boolean;
    /**
     * Empty Constructor
     */
    constructor() {
        this.phone = "";
        this.name = "";
        this.addresses = [];
        this.favorites = [];
        this.orders = [];
        this.blocked = false;
        this.img = new Image();
        this.email = "";
        this.informed = [];
        this.selectedLanguage = "";
        this.giftPoints = 0;
        this.doneSurveys = {};
        this.agreedWithTermsAndConditions = false;
        this.settings = {};
        this.dateOfCreation = 0;
        this.gold = false;
    }

    isEmpty() {
        return (!(this.name && this.phone))
    }

    loadFromJson(jsonObject: any) {
        if (jsonObject) {
            if (jsonObject.phone) {
                this.phone = jsonObject.phone;
            }
            if (jsonObject.name) {
                this.name = jsonObject.name;
            }
            if (jsonObject.addresses) {
                this.addresses = jsonObject.addresses;
            }
            if (jsonObject.favorites) {
                this.favorites = jsonObject.favorites;
            }
            if (jsonObject.orders) {
                this.orders = jsonObject.orders;
            }
            if (jsonObject.blocked) {
                this.blocked = jsonObject.blocked;
            }
            if (jsonObject.img) {
                let img = new Image();
                img.loadFromJson(jsonObject.img);
                this.img = img;
            }
            if (jsonObject.email) {
                this.email = jsonObject.email;
            }
            if (jsonObject.informed) {
                this.informed = jsonObject.informed;
            }
            if (jsonObject.selectedLanguage) {
                this.selectedLanguage = jsonObject.selectedLanguage;
            }
            if (jsonObject.giftPoints) {
                this.giftPoints = jsonObject.giftPoints;
            }
            if (jsonObject.doneSurveys) {
                this.doneSurveys = jsonObject.doneSurveys;
            }
            if (jsonObject.agreedWithTermsAndConditions) {
                this.agreedWithTermsAndConditions = jsonObject.agreedWithTermsAndConditions;
            }
            if (jsonObject.settings) {
                this.settings = jsonObject.settings;
            }
            if (jsonObject.dateOfCreation) {
                this.dateOfCreation = jsonObject.dateOfCreation;
            }
            if (jsonObject.gold) {
                this.gold = jsonObject.gold;
            }
        }
    }

    toJason(): any {
        return {
            phone: this.phone,
            name: this.name,
            addresses: this.addresses,
            favorites: this.favorites,
            orders: this.orders,
            blocked: this.blocked,
            img: this.img.toJason(),
            email: this.email,
            informed: this.informed,
            selectedLanguage: this.selectedLanguage,
            giftPoints: this.giftPoints,
            doneSurveys: this.doneSurveys,
            agreedWithTermsAndConditions: this.agreedWithTermsAndConditions,
            settings: this.settings,
            dateOfCreation: this.dateOfCreation,
            gold: this.gold,
        };
    }
}

export default User;
