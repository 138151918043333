import '../../style/login-page.css';
import AuthenticationManager from '../../database/authentication.js'
var createReactClass = require('create-react-class');
var React = require('react');

var AdminVerificationFail = createReactClass({

    onClick: function (event) {
        switch (event.target.name) {
            case "login_back":
                this.props.initiateLogOut();
                break;
            default:
                break;
        }
    },
    render: function () {
        return (
            <div class='login-form message'>
                <div class='login-error-message-content-wrap'>
                    <div class="login-error-message-content-caption">
                        <h4>Nemate dodeljene permisije!</h4>
                    </div>
                    <div class="login-error-message-content">
                        <p>Nemate dodeljene privilegije da bi ste pristupili sadržaju portala. </p>
                        <p>Molimo Vas, kontaktirajte adminitratore, putem sledece e-mail adrese, da bi Vam privilegije bile dodeljene:</p>
                        <p>admin@demango.rs</p>
                        <br />
                        <a id="login_back" name="login_back" onClick={this.onClick} className="link-to-terms">Povratak na 'LogIn' stranicu</a>
                    </div>
                </div>
            </div>
        );
    }
});

export default AdminVerificationFail;