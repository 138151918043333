/* eslint-disable import/no-named-as-default */
/* eslint-disable require-jsdoc */
import { Address } from "./address";
import enumRequestState from "./enums/request_state";
import StoreCart from "./storeCart";

/**
 * Placeholder class for Order attributes.
 */
export class Order {
  orderId: string;
  dateOfCreation: Date;
  receiverAddressId: string;
  receiverAddress: Address;
  senderAddressId: string;
  senderAddress: Address;
  deliveryStartTime: Date;
  estimatedDeliveryTime: number;
  comment: string;
  state: enumRequestState;
  changesLog: [];
  addedByAdmin: boolean;
  payed: boolean;
  cart: StoreCart;
  cancellationReason: string;
  hasRating: boolean;
  seen: boolean;
  updating: boolean;
  deliveryCost: number;
  deliveryId: string;

  /**
   * Empty Constructor
   */
  constructor() {
    this.orderId = "";
    this.dateOfCreation = new Date();
    this.receiverAddressId = "";
    this.senderAddressId = "";
    this.deliveryStartTime = new Date();
    this.estimatedDeliveryTime = 0;
    this.comment = "";
    this.state = enumRequestState.PENDING_FOR_CLIENT;
    this.changesLog = [];
    this.addedByAdmin = false;
    this.payed = false;
    this.receiverAddress = new Address();
    this.senderAddress = new Address();
    this.cart = new StoreCart();
    this.cancellationReason = "";
    this.hasRating = false;
    this.seen = false;
    this.updating = false;
    this.deliveryCost = 0;
    this.deliveryId = "";
  }

  isEmpty() {
    let empty: boolean = true;
    if (this.receiverAddress.address !== "" &&
      this.receiverAddress.name !== "" &&
      this.receiverAddress.phone !== "" &&
      this.senderAddress.address !== "" &&
      this.senderAddress.name !== "" &&
      this.senderAddress.phone !== "") {
      empty = false;
    }
    return empty;
  }

  loadFromJson(jsonObject: any) {
    if (jsonObject) {
      if (jsonObject.dateOfCreation) {
        try {
          const date = new Date(jsonObject.dateOfCreation);
          if (date) {
            this.dateOfCreation = date;
          }
        } catch {
          this.dateOfCreation = new Date();
        }
      }
      if (jsonObject.receiverAddressId) {
        this.receiverAddressId = jsonObject.receiverAddressId;
      }
      if (jsonObject.senderAddressId) {
        this.senderAddressId = jsonObject.senderAddressId;
      }
      if (jsonObject.deliveryStartTime) {
        try {
          const date = new Date(jsonObject.deliveryStartTime);
          if (date) {
            this.deliveryStartTime = date;
          }
        } catch {
          this.deliveryStartTime = new Date();
        }
      }
      if (jsonObject.estimatedDeliveryTime) {
        this.estimatedDeliveryTime = jsonObject.estimatedDeliveryTime;
      }
      if (jsonObject.comment) {
        this.comment = jsonObject.comment;
      }
      if (jsonObject.state) {
        this.state = jsonObject.state;
      }
      if (jsonObject.changesLog) {
        this.changesLog = jsonObject.changesLog;
      }
      if (jsonObject.addedByAdmin) {
        this.addedByAdmin = jsonObject.addedByAdmin;
      }
      if (jsonObject.payed) {
        this.payed = jsonObject.payed;
      }
      if (jsonObject.receiverAddress) {
        const tempRecAddress: Address = new Address();
        tempRecAddress.loadFromJson(jsonObject.receiverAddress);
        this.receiverAddressId = tempRecAddress.id;
        this.receiverAddress = tempRecAddress;
      }
      if (jsonObject.senderAddress) {
        const tempSenderAddress: Address = new Address();
        tempSenderAddress.loadFromJson(jsonObject.senderAddress);
        this.receiverAddressId = tempSenderAddress.id;
        this.senderAddress = tempSenderAddress;
      }
      if (jsonObject.cart) {
        const tempCart: StoreCart = new StoreCart();
        tempCart.loadFromJson(jsonObject.cart);
        this.cart = tempCart;
      }
      if (jsonObject.cancellationReason) {
        this.cancellationReason = jsonObject.cancellationReason;
      }
      if (jsonObject.hasRating) {
        this.hasRating = jsonObject.hasRating;
      }
      if (jsonObject.seen) {
        this.seen = jsonObject.seen;
      }
      if (jsonObject.deliveryCost) {
        this.deliveryCost = jsonObject.deliveryCost;
      }
      if (jsonObject.deliveryId) {
        this.deliveryId = jsonObject.deliveryId;
      }
      if (jsonObject.seen) {
        this.seen = jsonObject.seen;
      }
      if (jsonObject.orderId) {
        this.orderId = jsonObject.orderId;
      }
      else {
        this.orderId = this.generateOrderId();
      }
    }
  }

  getStringStateValue(forCSS = false): string {
    switch (this.state) {
      case enumRequestState.PENDING_FOR_CLIENT:
        return forCSS ? "pending-for-client" : "Čeka se odobrenje lokala";
      case enumRequestState.PENDING_FOR_DELIVERY:
        return forCSS ? "pending-for-delivery" : "Čeka se dostava";
      case enumRequestState.IN_PROGRESS:
        return forCSS ? "in-progress" : "Dostava u toku";
      case enumRequestState.DONE:
        return forCSS ? "done" : "Dostavljena";
      case enumRequestState.CANCELED:
        return forCSS ? "canceled" : "Otkazana";
      default:
        return forCSS ? "done" : "";
    }
  }

  toJason(): any {
    let retObj: any = {
      orderId: this.orderId,
      dateOfCreation: this.dateOfCreation.getTime(),
      receiverAddressId: this.receiverAddressId,
      senderAddressId: this.senderAddressId,
      deliveryStartTime: this.deliveryStartTime.getTime(),
      estimatedDeliveryTime: this.estimatedDeliveryTime,
      comment: this.comment,
      state: this.state,
      changesLog: this.changesLog,
      addedByAdmin: this.addedByAdmin,
      payed: this.payed,
      senderAddress: this.senderAddress.toJason(),
      receiverAddress: this.receiverAddress.toJason(),
      cancellationReason: this.cancellationReason,
      hasRating: this.hasRating,
      deliveryCost: this.deliveryCost,
      deliveryId: this.deliveryId,
      seen: this.seen,
    };
    if (!this.cart.isEmpty()) {
      retObj.cart = this.cart.toJson();
    }

    return retObj;
  }

  get Id(): string {
    if (!this.orderId) {
      this.orderId = this.generateOrderId();
    }
    return this.orderId;
  }

  generateUndefinedSender() {
    this.senderAddress.address = "undefined";
    this.senderAddress.name = "undefined";
    this.senderAddress.phone = "undefined";
    this.senderAddressId = this.senderAddress.Id;
  }

  clearUndefinedSender() {
    this.senderAddress.address = "";
    this.senderAddress.name = "";
    this.senderAddress.phone = "";
    this.senderAddressId = "";
  }

  generateUndefinedReceiver() {
    this.receiverAddress.address = "undefined";
    this.receiverAddress.name = "undefined";
    this.receiverAddress.phone = "undefined";
    this.receiverAddressId = this.receiverAddress.Id;
  }

  partOfID() {
    return this.orderId.split('_')[0];
  }

  generateOrderId(): string {
    // This is the cheeper option to have a unique ID.
    // Other option would be to ask DB to generate it.
    // In that case, it could be some hex number.
    let _id = this.senderAddress.name.toLowerCase() + "_" + this.receiverAddress.name.toLowerCase();
    _id = _id.replace(/ /g, "");
    _id = _id.replace(/[/|~`,.;:'"{}@^#$%=?&*()!<>+-]/g, "");
    _id = this.dateOfCreation.getTime() + "_" + _id;
    return _id;
  }
}

export default Order;
