import ClientType from "../model/clientType";
import React from "react";

/**
 * Returns list of client type names that corresponds with given ids. 
 * If the list of Ids is empty, it will return all names.
 * @param {*} clientTypesIds 
 * @param {*} clientTypes 
 * @returns list of client type names
 */
export function getClientTypeNames(clientTypesIds, clientTypes) {
    let clientTypeNames = [];
    clientTypes.forEach(clientType => {
        if (clientTypesIds && clientTypesIds.length > 0) {
            clientTypesIds.forEach(realClientTypeId => {
                if (clientType.id === realClientTypeId) {
                    clientTypeNames.push(clientType.name);
                }
            });
        }
        else {
            clientTypeNames.push(clientType.name);
        }

    });
    return clientTypeNames;
}

/**
 * 
 * @param {*} idString 
 * @param {*} clientTypes 
 * @returns 
 */
export function generateTypeIdsFromString(idString, clientTypes) {
    let idArray = idString.split(",");
    let retArray = {};
    clientTypes.forEach(clientType => {
        idArray.forEach(idToCheck => {
            if (clientType.id === idToCheck) {
                retArray[idToCheck] = true;
            }
        });
    });
    return retArray;
}

export default { getClientTypeNames, generateTypeIdsFromString }