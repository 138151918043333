import '../../style/clients-page.css';
import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import CustomInput from '../libs-common/custom-input.js';
import ButtonsUpDown from '../libs-common/buttons-up-down.js';
import ButtonAddElement from '../libs-common/button-add-element.js';
import ButtonRemoveElement from '../libs-common/button-remove-element.js';
import ButtonLoadImg from '../libs-common/button-load-img.js';
import ButtonDiscardCard from '../libs-common/button-discard.js';
import ButtonSaveCard from '../libs-common/button-save-card.js';
import ButtonEditCard from '../libs-common/button-edit-card.js';
import ClientCardImg from '../libs-common/client-img.js';
import CustomInputCheckBox from '../libs-common/custom-check-box.js';
import ClientMenuSubitem from './static-client-menu-subitem.js';
import StorageHandler from '../../database/storage-handler.js';
import MenuSubitem from '../model/menuSubItem';
var createReactClass = require('create-react-class');
var React = require('react');

var ClientMenuItem = createReactClass({
    getInitialState: function () {
        this.props.dbHandler.addReloadMethod(this.onDbTransactionFinish);
        let storage_handler = new StorageHandler(this.onUploadChange, this.onUploadFinish);
        return {
            show_more: false,
            show_children: false,
            item: this.props.item,
            menu: this.props.menu,
            index: this.props.item.index,
            showOnWebsite: this.props.item.showOnWebsite,
            name: this.props.item.name,
            description: this.props.item.description,
            img: this.props.item.img.normal,
            price: this.props.item.price,
            use_price: this.props.item.use_price,
            items: this.props.item.items,
            remove_item: false,
            storage_handler: storage_handler,
            showMessageBox: false,
            isImgFromWeb: this.props.item.isImgFromWeb,
        }
    },
    componentDidUpdate(prevProps) {
        if (this.props.item.index !== prevProps.item.index) {
            this.reloadVariables();
        }
    },
    reloadVariables: function () {
        this.setState({
            item: this.props.item,
            menu: this.props.menu,
            index: this.props.item.index,
            showOnWebsite: this.props.item.showOnWebsite,
            name: this.props.item.name,
            description: this.props.item.description,
            img: this.props.item.img.normal,
            price: this.props.item.price,
            use_price: this.props.item.use_price,
            items: this.props.item.items,
            isImgFromWeb: this.props.item.isImgFromWeb,
        });
    },
    onEditClick: function () {
        let new_state = !this.state.show_more;
        this.setState({ show_more: new_state });
    },
    onSaveClick: function (save) {
        if (save) {
            let new_state = !this.state.show_more;
            this.setState({ show_more: new_state });
            this.state.item.name = this.state.name;
            this.state.item.showOnWebsite = this.state.showOnWebsite;
            this.state.item.description = this.state.description;
            this.state.item.img.normal = this.state.img;
            this.state.item.price = this.state.price;
            this.state.item.use_price = this.state.use_price;
            this.state.item.isImgFromWeb = this.state.isImgFromWeb

            this.props.dbHandler.addOrUpdateMenuDB(this.props.client.id, this.props.menu);
        }
        else {
            let new_state = !this.state.show_more;
            this.setState({ show_more: new_state });

            this.props.dbHandler.getMenusFromClientDB(this.props.client);
        }
    },
    showChildrenClick: function () {
        let new_state = !this.state.show_children;
        this.setState({ show_children: new_state });
    },
    onInputChange: function (event) {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({ [nam]: val });
    },
    onShowOnWebSiteChange: function () {
        let _showOnWebsite = this.state.showOnWebsite;
        this.setState({ showOnWebsite: !_showOnWebsite })
    },
    onUsePriceChange: function () {
        let _use_price = this.state.use_price;
        this.setState({ use_price: !_use_price })
    },
    isImgFromWebChange: function () {
        let _isImgFromWeb = this.state.isImgFromWeb;
        this.setState({ isImgFromWeb: !_isImgFromWeb })
    },
    onRemoveElement() {
        this.props.menu.removeItem(this.state.item);
        this.props.dbHandler.addOrUpdateMenuDB(this.props.client.id, this.props.menu);
        // let _remove_item = !this.state.remove_item;
        // this.setState({ remove_item: _remove_item });
    },
    onMoveUp: function () {
        this.state.menu.moveUp(this.state.index)
        this.props.dbHandler.addOrUpdateMenuDB(this.props.client.id, this.props.menu);
        this.setState({
            index: this.state.item.index
        })
    },
    onMoveDown: function () {
        this.state.menu.moveDown(this.state.index)
        this.props.dbHandler.addOrUpdateMenuDB(this.props.client.id, this.props.menu);
        this.setState({
            index: this.state.item.index
        })
    },
    generateMenuSubitems: function () {
        let items = [];
        for (var key in this.state.items) {
            if (this.state.items[key]) {
                items.push(<ClientMenuSubitem
                    subitem={this.state.items[key]}
                    client={this.props.client}
                    menu={this.props.menu}
                    item={this.state.item}
                    dbHandler={this.props.dbHandler} />);
            }
        }
        return items;
    },
    addNewElement: function () {
        this.state.item.addNewItem();
        this.props.dbHandler.addOrUpdateMenuDB(this.props.client.id, this.props.menu);
    },
    onDbTransactionFinish: function () {
        this.setState({ items: this.state.item.items });
    },
    onOpenFile: function (event) {
        if (event.target.files[0]) {
            let file = event.target.files[0];
            this.state.storage_handler.uploadImageToRef(this.props.client.id, file);
        }
    },
    onUploadChange: function (percentage) {
        //TODO : Show progress bar
    },
    onUploadFinish: function (url) {
        this.state.item.img.normal = url;
        this.setState({ img: url });
    },
    copyItemsClick: function () {
        this.setState({ showMessageBox: true });
    },
    copySubitems: function () {
        (Object.values(this.state.menu.items)).forEach(menuItemObj => {
            if (menuItemObj !== this.state.item) {
                menuItemObj.items = {};
                (Object.values(this.state.item.items)).forEach((subItem, index) => {
                    let subItemNew = new MenuSubitem();
                    subItemNew.loadFromJson(subItem.toJson());
                    menuItemObj.items[index] = subItemNew;
                });
            }
        });
        this.props.dbHandler.addOrUpdateMenuDB(this.props.client.id, this.props.menu);
    },
    render: function () {
        if (this.state.showMessageBox) {
            return (
                <div className="message-box-container">
                    <div className="message-box-content flex-column border-radius">
                        <div className="message-box-header">
                            <p>PAZI STA RADIS!</p>
                        </div>
                        <div className="message-box-body">
                            <p>Ne znam sta smears, ali.... <br /> Zelis li da nastavis? </p>
                        </div>
                        <div className="message-box-footer">
                            <button className="btn-style-white" onClick={() => {
                                this.setState({ showMessageBox: false });
                                this.copySubitems();
                            }}>Da</button>
                            <button className="btn-style-white" onClick={() => {
                                this.setState({ showMessageBox: false });
                            }}>Ne</button>
                        </div>
                    </div>
                </div>);
        }
        if (this.state.remove_item) { return null; }
        if (!this.state.show_more) {
            return (
                <div class="static-clients-menu-wrapper">
                    <div class="static-clients-card" >
                        <div class="static-clients-card-div-img">
                            <ClientCardImg photoUrl={this.state.img} />
                        </div>
                        <div class="static-clients-menu-item-info">
                            <div class="static-clients-card-info-row">
                                <h6 class="static-cl-card-title card-title">id: {this.state.item.id}</h6>
                                <h6 class="static-cl-card-title card-title">index: {this.state.index}</h6>
                            </div>
                            <div class="static-clients-card-info-row">
                                <div class="static-clients-card-info-half">
                                    <h5 class="static-cl-card-title card-title">{this.state.name}</h5>
                                </div>
                                <div class="static-clients-card-info-half">
                                    <h5 class="static-cl-card-title card-title">{this.state.price}</h5>
                                </div>
                            </div>
                            <div class="static-clients-card-info-row">
                                <h6 class="static-cl-card-title card-title">Prikaži na sajtu: {this.state.showOnWebsite ? "Da" : "Ne"}</h6>
                            </div>
                            <div class="static-clients-card-info-row">
                                <h6 class="static-cl-card-title card-title">Koristi cenu: {this.state.use_price ? "Da" : "Ne"}</h6>
                            </div>
                            <div class="static-clients-card-info-row">
                                <h6 class="static-cl-card-title card-title">Simbolicna slika (sa neta): {this.state.isImgFromWeb ? "Da" : "Ne"}</h6>
                            </div>
                            <div class="static-clients-card-info-row">
                                <div class="static-clients-card-info-half">
                                    <h6 class="static-cl-card-title card-title">{this.state.description}</h6>
                                </div>
                            </div>
                        </div>
                        <ButtonsUpDown onClickUp={this.onMoveUp} onClickDown={this.onMoveDown} />
                        <ButtonEditCard onClick={this.onEditClick} />
                        <div class="static-cl-card-right-btn-div">
                            <button onClick={this.copyItemsClick} type="button"
                                className={"btn btn-secondary fixed-width"}>
                                Kopiraj subiteme u ostale elemente ovog menija.
                            </button>
                        </div>
                    </div>
                    <div class="static-cl-card-show-children">
                        {this.state.show_children ?
                            <div class="static-cl-card-show-children-content">
                                {this.generateMenuSubitems()}
                                <div class="static-clients-menu-wrapper">
                                    <ButtonAddElement onClick={this.addNewElement} />
                                </div>
                                <div class="static-cl-card-show-children-space">
                                    <h5 class="white-text"> . </h5>
                                </div>
                            </div>
                            : null}
                        <div class="static-cl-card-show-children-button" onClick={this.showChildrenClick}>
                            <i class={"arrow" + (this.state.show_children ? " up" : " down")}></i>
                        </div>
                    </div>
                </div >
            );
        }
        else {
            return (
                <div class="static-clients-menu-wrapper">
                    <div class="static-clients-card" >
                        <div class="static-clients-card-div-img">
                            <ClientCardImg photoUrl={this.state.img} />
                            <ButtonLoadImg onChange={this.onOpenFile} />
                        </div>
                        <div class="static-clients-menu-item-info">
                            <div class="static-clients-card-info-row">
                                <div class="static-clients-card-info-third">
                                    <div class="static-clients-card-info-imput-wrapper">
                                        <CustomInput inputId="name"
                                            inputValue={this.state.name}
                                            labelValue="Naziv"
                                            placeholder="Naziv"
                                            onChange={this.onInputChange} readOnly={false} />
                                    </div>
                                </div>
                                <div class="static-clients-card-info-tenth">
                                    <h5 class="darksalmon-text"> . </h5>
                                </div>
                                <div class="static-clients-card-info-third">
                                    <div class="static-clients-card-info-imput-wrapper">
                                        <CustomInput inputId="price"
                                            inputValue={this.state.price}
                                            labelValue="Cena"
                                            placeholder=""
                                            onChange={this.onInputChange} readOnly={false} />
                                    </div>
                                </div>
                                <div class="static-clients-card-info-tenth">
                                    <h5 class="darksalmon-text"> . </h5>
                                </div>
                                <ButtonRemoveElement onClick={this.onRemoveElement} />
                            </div>
                            <div class="static-clients-card-info-row">
                                <div class="static-clients-card-info-imput-wrapper">
                                    <CustomInputCheckBox isChecked={this.state.showOnWebsite}
                                        labelValue={"Prikaži na sajtu"}
                                        onChange={this.onShowOnWebSiteChange}
                                        value={this.state.showOnWebsite} />
                                </div>
                            </div>
                            <div class="static-clients-card-info-row">
                                <div class="static-clients-card-info-imput-wrapper">
                                    <CustomInputCheckBox isChecked={this.state.use_price}
                                        labelValue={"Koristi cenu"}
                                        onChange={this.onUsePriceChange}
                                        value={this.state.use_price} />
                                </div>
                            </div>
                            <div class="static-clients-card-info-row">
                                <div class="static-clients-card-info-imput-wrapper">
                                    <CustomInputCheckBox isChecked={this.state.isImgFromWeb}
                                        labelValue={"Simbolicna slika (sa neta)"}
                                        onChange={this.isImgFromWebChange}
                                        value={this.state.isImgFromWeb} />
                                </div>
                            </div>
                            <div class="static-clients-card-info-row">
                                <label class="form-label" for="textAreaExample">Opis:</label>
                                <textarea class="form-control" id="description" rows="4"
                                    onChange={this.onInputChange} name="description">
                                    {this.state.description}
                                </textarea>
                            </div>
                        </div>
                        <ButtonsUpDown onClickUp={this.onMoveUp} onClickDown={this.onMoveDown} />
                        <ButtonSaveCard onClick={this.onSaveClick.bind(this, true)} />
                        <ButtonDiscardCard onClick={this.onSaveClick.bind(this, false)} />
                    </div>
                    <div class="static-cl-card-show-children">
                        {this.state.show_children ?
                            <div class="static-cl-card-show-children-content">
                                <div class="static-cl-card-show-children-content">
                                    {this.generateMenuSubitems()}
                                    <div class="static-clients-menu-wrapper">
                                        <ButtonAddElement onClick={this.addNewElement} />
                                    </div>
                                    <div class="static-cl-card-show-children-space">
                                        <h5 class="white-text"> . </h5>
                                    </div>
                                </div>
                            </div>
                            : null}
                        <div class="static-cl-card-show-children-button" onClick={this.showChildrenClick}>
                            <i class={"arrow" + (this.state.show_children ? " up" : " down")}></i>
                        </div>
                    </div>
                </div>
            );
        }
    }
});

export default ClientMenuItem;