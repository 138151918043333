/* eslint-disable react/no-direct-mutation-state */
import '../../style/clients-page.css';
import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import CustomInput from '../libs-common/custom-input.js';
import ButtonLoadImg from '../libs-common/button-load-img.js';
import ButtonAddLocation from '../libs-common/button-add-location.js';
import ButtonRemoveLocation from '../libs-common/button-remove-location.js';
import ButtonRemoveElement from '../libs-common/button-remove-element.js';
import ButtonAddElement from '../libs-common/button-add-element.js';
import ButtonDiscardCard from '../libs-common/button-discard.js';
import ButtonSaveCard from '../libs-common/button-save-card.js';
import ButtonEditCard from '../libs-common/button-edit-card.js';
import ButtonsUpDown from '../libs-common/buttons-up-down.js';
import ClientCardImg from '../libs-common/client-img.js';
import CustomInputCheckBox from '../libs-common/custom-check-box.js';
import InputWorkTime from '../libs-common/input-work-time.js';
import ClientMenu from './static-client-menu.js';
import StorageHandler from '../../database/storage-handler.js';
import { generateTypeIdsFromString, getClientTypeNames } from "../libs-common/ClientTypeHelper";
import WorkingTime from '../model/workingTime';
import Address from '../model/address';
import enumOrderPayer from '../model/enums/orderPayer';
import CustomDropdown from '../libs-common/custom-dropdown';
var createReactClass = require('create-react-class');
var React = require('react');

var ClientCard = createReactClass({
    getInitialState: function () {
        let storage_handler = new StorageHandler(this.onUploadChange, this.onUploadFinish);
        this.props.dbHandler.addReloadMethod(this.onDbTransactionFinish)
        if (this.props.client.workingTime.length === 0) {
            this.props.client.workingTime.push(new WorkingTime());
        }
        let _addresses = [new Address()];
        _addresses[0].address = "Nova adresa";
        if (this.props.client.locations && this.props.client.locations.length > 0) {
            _addresses = this.props.client.locations;
        }
        return {
            show_more: false,
            show_children: false,
            client: this.props.client,
            clients: this.props.clients,
            menus: this.props.client.menus,
            index: this.props.client.index,
            name: this.props.client.name,
            type: this.props.client.type,
            info: this.props.client.info,
            isOverloaded: this.props.client.isOverloaded,
            showOnWebsite: this.props.client.showOnWebsite,
            locations: _addresses,
            img: this.props.client.img.normal,
            headerImg: this.props.client.headerImg.normal,
            workingTime: this.props.client.workingTime,
            enablePromotion: this.props.client.enablePromotion,
            remove_client: false,
            menus_to_show: this.generateMenus(this.props.client.menus, this.props.client),
            storage_handler: storage_handler,
            clientTypes: this.props.clientTypes,
            typeIds: Object.keys(this.props.client.types).join(","),
            uploadingImage: "",
            orderPayer: this.props.client.orderPayer,
            minimumDeliveryCost: this.props.client.minimumDeliveryCost,
            deliveryId: this.props.client.deliveryId,
            priority: this.props.client.priority,
        }
    },
    componentDidUpdate(prevProps) {
        if ((this.props.client.index !== prevProps.client.index) ||
            (this.props.clientTypes !== prevProps.clientTypes)) {
            this.reloadVariables();
        }
    },
    reloadVariables: function () {
        let _addresses = [new Address()];
        _addresses[0].address = "Nova adresa"
        if (this.props.client.locations && this.props.client.locations.length > 0) {
            _addresses = this.props.client.locations;
        }
        this.setState({
            client: this.props.client,
            clients: this.props.clients,
            menus: this.props.client.menus,
            index: this.props.client.index,
            name: this.props.client.name,
            type: this.props.client.type,
            info: this.props.client.info,
            isOverloaded: this.props.client.isOverloaded,
            showOnWebsite: this.props.client.showOnWebsite,
            locations: _addresses,
            img: this.props.client.img.normal,
            headerImg: this.props.client.headerImg.normal,
            workingTime: this.props.client.workingTime,
            enablePromotion: this.props.client.enablePromotion,
            menus_to_show: this.generateMenus(this.props.client.menus, this.props.client),
            clientTypes: this.props.clientTypes,
            typeIds: Object.keys(this.props.client.types).join(","),
            orderPayer: this.props.client.orderPayer,
            minimumDeliveryCost: this.props.client.minimumDeliveryCost,
            deliveryId: this.props.client.deliveryId,
            priority: this.props.client.priority,
        });
    },
    onEditClick: function () {
        let new_state = !this.state.show_more;
        this.setState({ show_more: new_state });
    },
    onSaveClick: function (save) {
        if (save && !(this.state.showOnWebsite && this.state.deliveryId === "")) {
            let new_state = !this.state.show_more;
            this.setState({ show_more: new_state });
            this.state.client.name = this.state.name;
            this.state.client.type = this.state.type;
            this.state.client.showOnWebsite = this.state.showOnWebsite;
            this.state.client.locations = this.state.locations;
            this.state.client.img.normal = this.state.img;
            this.state.client.headerImg.normal = this.state.headerImg;
            this.state.client.enablePromotion = this.state.enablePromotion;
            this.state.client.index = this.state.index;
            this.state.client.types = generateTypeIdsFromString(this.state.typeIds, this.state.clientTypes)
            this.state.client.workingTime = this.state.workingTime;
            this.state.client.info = this.state.info;
            this.state.client.isOverloaded = this.state.isOverloaded;
            this.state.client.orderPayer = this.state.orderPayer;
            this.state.client.minimumDeliveryCost = this.state.minimumDeliveryCost;
            this.state.client.deliveryId = this.state.deliveryId;
            this.state.client.priority = this.state.priority;
            this.props.dbHandler.addOrUpdateClientDB(this.state.client);
        }
        else {
            let new_state = !this.state.show_more;
            this.setState({ show_more: new_state });
            this.props.dbHandler.getClientsFromDB();
        }
    },
    showChildrenClick: function () {
        let new_state = !this.state.show_children;
        this.setState({ show_children: new_state });
        this.props.dbHandler.getMenusFromClientDB(this.state.client, this.onDbTransactionFinish);
    },
    onInputChange: function (event) {
        let args = event.target.name.split('_');
        if (args[0] === "address") {
            let locations = this.state.locations;
            locations[parseInt(args[1])].address = event.target.value;
            this.setState({ locations: locations });
        }
        else if (args[0] === "clientPhone") {
            let locations = this.state.locations;
            locations[parseInt(args[1])].phone = event.target.value;
            this.setState({ locations: locations });
        }
        else {
            let nam = event.target.name;
            let val = event.target.value;
            this.setState({ [nam]: val });
        }
    },
    onWorkTimeChange: function () {
        //TODO: Finish it!
    },
    onShowOnWebSiteChange: function () {
        let _showOnWebsite = this.state.showOnWebsite;
        this.setState({ showOnWebsite: !_showOnWebsite })
    },
    onEnablePromotionChange: function () {
        let _enablePromotion = this.state.enablePromotion;
        this.setState({ enablePromotion: !_enablePromotion })
    },
    onIsOverloadedChange: function () {
        let _isOverloaded = this.state.isOverloaded;
        this.setState({ isOverloaded: !_isOverloaded })
    },
    onRemoveElement: function () {
        this.props.dbHandler.removeClientFromDB(this.props.client);
        let _remove_client = !this.state.remove_client;
        this.setState({ remove_client: _remove_client });
    },
    onMoveUp: function () {
        this.props.dbHandler.moveClientUp(this.state.index);
        this.setState({
            index: this.state.client.index
        })
    },
    onMoveDown: function () {
        this.props.dbHandler.moveClientDown(this.state.index);
        this.setState({
            index: this.state.client.index
        })
    },
    generateMenus: function (_menus, _client) {
        let menus = [];
        for (var key in _menus) {
            if (_menus[key]) {
                menus.push(<ClientMenu menu={_menus[key]}
                    client={_client}
                    dbHandler={this.props.dbHandler} />);
            }
        }
        return menus;
    },
    addNewElement: function () {
        let menu = this.state.client.addNewMenu();
        this.props.dbHandler.addOrUpdateMenuDB(this.state.client.id, menu);
    },
    onDbTransactionFinish: function () {
        this.setState({
            menus: this.state.client.menus,
            menus_to_show: this.generateMenus(this.state.client.menus, this.state.client)
        });
        this.reloadVariables();
    },
    onLocationAdd: function (index1, index2) {
        let client = this.state.client;
        let locations = this.state.locations;
        if (index2 === null) {
            client.addNewLocation();
        }
        else {
            locations[index1].addPhone("");
        }
        this.setState({
            client: client,
            locations: locations
        });
    },
    onLocationRemove: function (index1, index2) {
        let client = this.state.client;
        let locations = this.state.locations;
        if (index2 === null) {
            client.removeLocationAtIndex(index1);
        }
        else {
            locations[index1].removePhoneAtIndex(index2);
        }
        this.setState({
            client: client,
            locations: locations
        });
    },
    onOpenFile: function (event) {
        console.log(event);
        if (event.target.files[0] && event.target.id) {
            let file = event.target.files[0];
            this.state.storage_handler.uploadImageToRef(this.state.client.id, file);
            this.setState({ uploadingImage: event.target.id })
        }
    },
    onUploadChange: function (percentage) {
        //TODO : Show progress bar
    },
    onUploadFinish: function (url) {
        switch (this.state.uploadingImage) {
            case "profile":
                this.state.client.img.normal = url;
                this.setState({ img: url });
                break;
            case "header":
                this.state.client.headerImg.normal = url;
                this.setState({ headerImg: url });
                break;

            default:
                break;
        }

    },
    getOrderPayerToString: function (payerEnum) {
        switch (payerEnum) {
            case enumOrderPayer.USER:
                return "Korisnik";
            case enumOrderPayer.CLIENT:
                return "Klijent (Lokal)";
            case enumOrderPayer.DELIVERY:
                return "Kurir";
            case enumOrderPayer.WEBSITE:
                return "deMango";

            default: return "Unknown";
        }
    },
    render: function () {
        if (this.state.remove_client) { return null; }
        if (!this.state.show_more) {
            return (
                <div class="static-clients-card-wrapper">
                    <div class="static-clients-card" >
                        <div class="static-clients-card-div-img">
                            <ClientCardImg photoUrl={this.state.img} />
                        </div>
                        <div class="static-clients-card-info">
                            <div class="static-clients-card-info-row">
                                <h6 class="static-cl-card-title card-title">id: {this.state.client.id}</h6>
                                <h6 class="static-cl-card-title card-title">index : {this.state.client.index}</h6>
                            </div>
                            <div class="static-clients-card-info-row">
                                <div class="static-clients-card-info-quarter">
                                    <h5 class="static-cl-card-title card-title">{this.state.name}</h5>
                                </div>
                                <div class="static-clients-card-info-quarter">
                                    <h6 class="static-cl-card-title card-title">{Object.keys(this.state.client.types).length > 0 ?
                                        getClientTypeNames(Object.keys(this.state.client.types), this.state.clientTypes).join(', ') :
                                        "Nema setovanih tipova!"}</h6>
                                </div>
                                <div class="static-clients-card-info-half">
                                    {this.state.client.workingTime.map(function (working_time) {
                                        return (
                                            <div class="static-clients-card-info-row">
                                                <h6 class="static-cl-card-title card-title">{working_time.toString()}</h6>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            <div class="static-clients-card-info-row">
                                <div class="static-clients-card-info-quarter">
                                    <h6 class="static-cl-card-title card-title">Prikaži na sajtu: {this.state.showOnWebsite ? "Da" : "Ne"}</h6>
                                </div>
                                <div class="static-clients-card-info-quarter">
                                    <h6 class="static-cl-card-title card-title">Zovi telefonom: {this.state.informMeByPhone ? "Da" : "Ne"}</h6>
                                </div>
                                <div class="static-clients-card-info-quarter">
                                    <h6 class="static-cl-card-title card-title">Promocija: {this.state.enablePromotion ? "Da" : "Ne"}</h6>
                                </div>
                                <div class="static-clients-card-info-quarter">
                                    <h6 class="static-cl-card-title card-title">Prebukiran: {this.state.isOverloaded ? "Da" : "Ne"}</h6>
                                </div>
                            </div>
                            <div class="static-clients-card-info-row">
                                <div class="static-clients-card-info-quarter">
                                    <h6 class="static-cl-card-title card-title">Platilac dostave: {this.getOrderPayerToString(this.state.orderPayer)}</h6>
                                </div>
                                <div class="static-clients-card-info-quarter">
                                    <h6 class="static-cl-card-title card-title">Pocetna cena dostave: {this.state.minimumDeliveryCost}</h6>
                                </div>
                            </div>
                            <div class="static-clients-card-info-row">
                                <div class="static-clients-card-info-quarter">
                                    <h6 class="static-cl-card-title card-title">ID Dostave: {this.state.deliveryId}</h6>
                                </div>
                                <div class="static-clients-card-info-quarter">
                                    <h6 class="static-cl-card-title card-title">Prioritet u izlistavanju: {this.state.priority}</h6>
                                </div>
                            </div>
                            {this.state.locations.map(function (location) {
                                return (
                                    <>
                                        <div class="static-clients-card-info-row">
                                            <div class="static-clients-card-info-half">
                                                <h6 class="static-cl-card-title card-title">{location.address}</h6>
                                            </div>
                                        </div>
                                        <div class="static-clients-card-info-row">
                                            <div class="static-clients-card-info-tenth">
                                                <h5 class="darksalmon-text"> . </h5>
                                            </div>
                                            <div class="static-clients-card-info-half">
                                                <h6 class="static-cl-card-title card-title">{location.phone}</h6>
                                            </div>
                                        </div>
                                    </>
                                );
                            }.bind(this))}
                            <div class="static-clients-card-info-row">
                                <div class="static-clients-card-info-half">
                                    <h6 class="static-cl-card-title card-title">{this.state.info}</h6>
                                </div>
                            </div>
                        </div>
                        <ButtonsUpDown onClickUp={this.onMoveUp} onClickDown={this.onMoveDown} />
                        <ButtonEditCard onClick={this.onEditClick} />
                    </div>
                    <div class="static-cl-card-show-children">
                        {this.state.show_children ?
                            <div class="static-cl-card-show-children-content">
                                {this.state.menus_to_show}
                                <div class="static-clients-menu-wrapper">
                                    <ButtonAddElement onClick={this.addNewElement} />
                                </div>
                                <div class="static-cl-card-show-children-space">
                                    <h5 class="white-text"> . </h5>
                                </div>
                            </div>
                            : null}
                        <div class="static-cl-card-show-children-button" onClick={this.showChildrenClick}>
                            <i class={"arrow" + (this.state.show_children ? " up" : " down")}></i>
                        </div>
                    </div>
                </div >
            );
        }
        else {
            return (
                <div class="static-clients-card-wrapper">
                    <div class="static-clients-card" >
                        <div class="static-clients-card-div-img">
                            <div class="static-clients-card-info-row">
                                <ClientCardImg photoUrl={this.state.img} />
                                <ButtonLoadImg onChange={this.onOpenFile} id="profile" />
                            </div>
                            <div class="static-clients-card-info-row">
                                <p>Header</p>
                                <ClientCardImg photoUrl={this.state.headerImg} />
                                <ButtonLoadImg onChange={this.onOpenFile} id="header" />
                            </div>
                        </div>
                        <div class="static-clients-card-info">
                            <div class="static-clients-card-info-row">
                                <div class="static-clients-card-info-half">
                                    <div class="static-clients-card-info-imput-wrapper">
                                        <CustomInput inputId="index"
                                            inputValue={this.state.index}
                                            labelValue="Index"
                                            placeholder="Index"
                                            onChange={this.onInputChange} readOnly={false} />
                                    </div>
                                </div>
                            </div>
                            <div class="static-clients-card-info-row">
                                <div class="static-clients-card-info-half">
                                    <div class="static-clients-card-info-imput-wrapper">
                                        <CustomInput inputId="name"
                                            inputValue={this.state.name}
                                            labelValue="Ime lokala"
                                            placeholder="Ime lokala"
                                            onChange={this.onInputChange} readOnly={false} />
                                    </div>
                                </div>
                                <div class="static-clients-card-info-half">
                                    <div class="static-clients-card-info-imput-wrapper">
                                        <CustomInput inputId="typeIds"
                                            inputValue={this.state.typeIds}
                                            labelValue="Tip lokala"
                                            placeholder="Tip lokala"
                                            onChange={this.onInputChange} readOnly={false} />
                                    </div>
                                </div>
                            </div>
                            <div class="static-clients-card-info-row">
                                <InputWorkTime
                                    client={this.props.client}
                                    labelValue={"Radno vreme:"} />
                            </div>
                            <div class="static-clients-card-info-row">
                                <div class="static-clients-card-info-row">
                                    <div class="static-clients-card-info-imput-wrapper">
                                        <CustomInputCheckBox isChecked={this.state.showOnWebsite}
                                            labelValue={"Prikaži na sajtu"}
                                            onChange={this.onShowOnWebSiteChange}
                                            value={this.state.showOnWebsite} />
                                    </div>
                                </div>
                                <div class="static-clients-card-info-row">
                                    <div class="static-clients-card-info-imput-wrapper">
                                        <CustomInputCheckBox isChecked={this.state.client.informMeByPhone}
                                            labelValue={"Zovi telefonom"}
                                            onChange={() => { this.state.client.informMeByPhone = !this.state.client.informMeByPhone }}
                                            value={this.state.client.informMeByPhone} />
                                    </div>
                                </div>
                                <div class="static-clients-card-info-row">
                                    <div class="static-clients-card-info-imput-wrapper">
                                        <CustomInputCheckBox isChecked={this.state.enablePromotion}
                                            labelValue={"Promocija"}
                                            onChange={this.onEnablePromotionChange}
                                            value={this.state.enablePromotion} />
                                    </div>
                                </div>
                                <div class="static-clients-card-info-row">
                                    <div class="static-clients-card-info-imput-wrapper">
                                        <CustomInputCheckBox isChecked={this.state.isOverloaded}
                                            labelValue={"Prebukiran"}
                                            onChange={this.onIsOverloadedChange}
                                            value={this.state.isOverloaded} />
                                    </div>
                                </div>
                            </div>
                            <div class="static-clients-card-info-row">
                                <div class="static-clients-card-info-half">
                                    <div class="static-clients-card-info-imput-wrapper">
                                        <CustomDropdown selectedValue={this.getOrderPayerToString(this.state.orderPayer)}
                                            labelValue={"Platilac dostave"}
                                            dropdownId="orderPayer"
                                            items={[
                                                enumOrderPayer.USER,
                                                enumOrderPayer.CLIENT,
                                                enumOrderPayer.WEBSITE,
                                                enumOrderPayer.DELIVERY,
                                            ]}
                                            onChange={(item) => { this.setState({ orderPayer: item }) }} />
                                    </div>
                                </div>
                                <div class="static-clients-card-info-half">
                                    <div class="static-clients-card-info-imput-wrapper">
                                        <CustomInput inputId="minimumDeliveryCost"
                                            inputValue={this.state.minimumDeliveryCost}
                                            labelValue="Pocetna cena dostave"
                                            placeholder="Pocetna cena dostave"
                                            onChange={this.onInputChange} readOnly={false} />
                                    </div>
                                </div>
                                <div class="static-clients-card-info-quarter">
                                    <h6 class="static-cl-card-title card-title">Platilac dostave: {this.getOrderPayerToString(this.state.orderPayer)}</h6>
                                </div>
                            </div>
                            <div class="static-clients-card-info-row">
                                <div class="static-clients-card-info-half">
                                    <div class="static-clients-card-info-imput-wrapper">
                                        <CustomInput inputId="deliveryId"
                                            inputValue={this.state.deliveryId}
                                            labelValue="ID Dostave"
                                            placeholder="ID Dostave"
                                            onChange={this.onInputChange} readOnly={false} />
                                    </div>
                                </div>
                                <div class="static-clients-card-info-half">
                                    <div class="static-clients-card-info-imput-wrapper">
                                        <CustomDropdown selectedValue={this.state.priority}
                                            labelValue={"Prioritet u izlistavanju"}
                                            dropdownId="priority"
                                            items={[
                                                0,
                                                1,
                                                2,
                                                3,
                                            ]}
                                            onChange={(item) => { this.setState({ priority: item }) }} />
                                    </div>
                                </div>
                            </div>
                            {this.state.locations.map(function (location, index) {
                                return (
                                    <>
                                        <div class="static-clients-card-info-row">
                                            <div class="static-clients-card-info-half">
                                                <div class="static-clients-card-info-imput-wrapper">
                                                    <CustomInput inputId={"address_" + index}
                                                        inputValue={location.address}
                                                        labelValue={"" + (index + 1) + ". adresa lokala"}
                                                        placeholder="Adresa lokala"
                                                        onChange={this.onInputChange} readOnly={false} />
                                                </div>
                                            </div>
                                            <div class="static-clients-card-info-half">
                                                <div class="static-clients-card-info-half">
                                                    <div class="static-clients-card-info-half">
                                                        <div class="static-clients-card-info-imput-wrapper">
                                                            {index === 0 ? null :
                                                                <ButtonRemoveLocation onClick={this.onLocationRemove}
                                                                    index1={index} index2={null} />}
                                                            {this.state.locations.length === (index + 1) ?
                                                                <ButtonAddLocation onClick={this.onLocationAdd}
                                                                    index1={index} index2={null} />
                                                                : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="static-clients-card-info-row">
                                            <div class="static-clients-card-info-tenth">
                                                <h5 class="darksalmon-text"> . </h5>
                                            </div>
                                            <div class="static-clients-card-info-quarter">
                                                <div class="static-clients-card-info-imput-wrapper">
                                                    <CustomInput inputId={"clientPhone_" + index}
                                                        inputValue={location.phone}
                                                        labelValue={"Telefon lokala"}
                                                        placeholder="Telefon lokala"
                                                        onChange={this.onInputChange} readOnly={false} />
                                                </div>
                                            </div>
                                        </div>
                                    </>);
                            }.bind(this))}
                            <div class="static-clients-card-info-row">
                                <label class="form-label" for="textAreaExample">Opis:</label>
                                <textarea class="form-control" id="info" rows="4"
                                    onChange={this.onInputChange} name="info">
                                    {this.state.info}
                                </textarea>
                            </div>
                        </div>
                        <ButtonsUpDown onClickUp={this.onMoveUp} onClickDown={this.onMoveDown} />
                        <ButtonSaveCard onClick={() => { this.onSaveClick(true) }} />
                        <ButtonDiscardCard onClick={() => { this.onSaveClick(false) }} />
                        <ButtonRemoveElement onClick={this.onRemoveElement} />
                    </div>
                    <div class="static-cl-card-show-children">
                        {this.state.show_children ?
                            <div class="static-cl-card-show-children-content">
                                {this.state.menus_to_show}
                                <div class="static-clients-menu-wrapper">
                                    <ButtonAddElement onClick={this.addNewElement} />
                                </div>
                                <div class="static-cl-card-show-children-space">
                                    <h5 class="white-text"> . </h5>
                                </div>
                            </div>
                            : null}
                        <div class="static-cl-card-show-children-button" onClick={this.showChildrenClick}>
                            <i class={"arrow" + (this.state.show_children ? " up" : " down")}></i>
                        </div>
                    </div>
                </div>
            );
        }
    }
});

export default ClientCard;