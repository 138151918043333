/* eslint-disable react/no-direct-mutation-state */
import '../../style/clients-page.css';
import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import CustomInput from '../libs-common/custom-input.js';
import ButtonEditCard from '../libs-common/button-edit-card.js';
import ButtonDiscardCard from '../libs-common/button-discard.js';
import ButtonRemoveElement from '../libs-common/button-remove-element.js';
import ButtonSaveCard from '../libs-common/button-save-card.js';
import ButtonsUpDown from '../libs-common/buttons-up-down.js';
import CustomInputCheckBox from '../libs-common/custom-check-box.js'
import MenuSubitem from '../model/menuSubItem';
var createReactClass = require('create-react-class');
var React = require('react');

var ClientMenuSubitem = createReactClass({
    getInitialState: function () {
        this.props.dbHandler.addReloadMethod(this.reloadVariables);
        return {
            show_more: false,
            show_children: false,
            item: this.props.item,
            subitem: this.props.subitem,
            index: this.props.subitem.index,
            showOnWebsite: this.props.subitem.showOnWebsite,
            name: this.props.subitem.name,
            price: this.props.subitem.price,
            isRadioButton: this.props.subitem.isRadioButton,
            group: this.props.subitem.group,
            remove_item: false,
            usePrice: this.props.subitem.usePrice,
            exclusive: this.props.subitem.exclusive,
            excludedGroups: this.props.subitem.excludedGroups.join(','),
            maxItemsFromGroup: this.props.subitem.maxItemsFromGroup,
            selectionPriceTrigger: this.props.subitem.selectionPriceTrigger,
            useInName: this.props.subitem.useInName,
        }
    },
    componentDidUpdate(prevProps) {
        if (this.props.subitem.index !== prevProps.subitem.index) {
            this.reloadVariables();
        }
    },
    reloadVariables: function () {
        this.setState({
            item: this.props.item,
            subitem: this.props.subitem,
            index: this.props.subitem.index,
            showOnWebsite: this.props.subitem.showOnWebsite,
            name: this.props.subitem.name,
            price: this.props.subitem.price,
            isRadioButton: this.props.subitem.isRadioButton,
            group: this.props.subitem.group,
            usePrice: this.props.subitem.usePrice,
            exclusive: this.props.subitem.exclusive,
            excludedGroups: this.props.subitem.excludedGroups.join(','),
            maxItemsFromGroup: this.props.subitem.maxItemsFromGroup,
            selectionPriceTrigger: this.props.subitem.selectionPriceTrigger,
            useInName: this.props.subitem.useInName,
        });
    },
    onEditClick: function () {
        let new_state = !this.state.show_more;
        this.setState({ show_more: new_state });
    },
    onSaveClick: function (save) {
        if (save) {
            let new_state = !this.state.show_more;
            this.setState({ show_more: new_state });
            this.state.subitem.name = this.state.name;
            this.state.subitem.showOnWebsite = this.state.showOnWebsite;
            this.state.subitem.price = this.state.price;
            this.state.subitem.isRadioButton = this.state.isRadioButton;
            this.state.subitem.group = this.state.group;
            this.state.subitem.usePrice = this.state.usePrice;
            this.state.subitem.exclusive = this.state.exclusive;
            this.state.subitem.excludedGroups = this.state.excludedGroups.split(',');
            this.state.subitem.maxItemsFromGroup = this.state.maxItemsFromGroup;
            this.state.subitem.selectionPriceTrigger = this.state.selectionPriceTrigger;
            this.state.subitem.useInName = this.state.useInName;
            this.props.dbHandler.addOrUpdateMenuDB(this.props.client.id, this.props.menu);
        }
        else {
            let new_state = !this.state.show_more;
            this.setState({ show_more: new_state });

            this.props.dbHandler.getMenusFromClientDB(this.props.client);
        }
    },
    showChildrenClick: function () {
        let new_state = !this.state.show_children;
        this.setState({ show_children: new_state });
    },
    onRemoveElement() {
        this.state.item.removeItem(this.state.subitem);
        this.props.dbHandler.addOrUpdateMenuDB(this.props.client.id, this.props.menu);
    },
    onShowOnWebSiteChange: function () {
        let _showOnWebsite = this.state.showOnWebsite;
        this.setState({ showOnWebsite: !_showOnWebsite })
    },
    onIsRadioButtonChange: function () {
        let _isRadioButton = this.state.isRadioButton;
        this.setState({ isRadioButton: !_isRadioButton })
    },
    onExclusiveChange: function () {
        let _exclusive = this.state.exclusive;
        this.setState({ exclusive: !_exclusive })
    },
    onUsePriceChange: function () {
        let _usePrice = this.state.usePrice;
        this.setState({ usePrice: !_usePrice })
    },
    onMoveUp: function () {
        this.state.item.moveUp(this.props.subitem.index);
        this.props.dbHandler.addOrUpdateMenuDB(this.props.client.id, this.props.menu);
    },
    onMoveDown: function () {
        this.state.item.moveDown(this.props.subitem.index);
        this.props.dbHandler.addOrUpdateMenuDB(this.props.client.id, this.props.menu);
    },
    onInputChange: function (event) {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({ [nam]: val });
    },
    copyItemsClick: function () {
        this.setState({ showMessageBox: true });
    },
    copySubitems: function () {
        (Object.values(this.props.menu.items)).forEach(menuItemObj => {
            if (menuItemObj !== this.state.item) {
                const keys = Object.keys(menuItemObj.items);
                let newKey = 0;
                while (keys.includes(String(newKey))) {
                    newKey++;
                }
                let newSubItem = new MenuSubitem();
                newSubItem.loadFromJson(this.state.subitem.toJson());
                menuItemObj.items[String(newKey)] = newSubItem;
                newSubItem.index = newKey;
            }
        });
        this.props.dbHandler.addOrUpdateMenuDB(this.props.client.id, this.props.menu);
    },
    render: function () {
        if (this.state.showMessageBox) {
            return (
                <div className="message-box-container">
                    <div className="message-box-content flex-column border-radius">
                        <div className="message-box-header">
                            <p>PAZI STA RADIS!</p>
                        </div>
                        <div className="message-box-body">
                            <p>Ne znam sta smears, ali.... <br /> Zelis li da nastavis? </p>
                        </div>
                        <div className="message-box-footer">
                            <button className="btn-style-white" onClick={() => {
                                this.setState({ showMessageBox: false });
                                this.copySubitems();
                            }}>Da</button>
                            <button className="btn-style-white" onClick={() => {
                                this.setState({ showMessageBox: false });
                            }}>Ne</button>
                        </div>
                    </div>
                </div>);
        }
        if (this.state.remove_item) { return null; }
        if (!this.state.show_more) {
            return (
                <div class="static-clients-menu-wrapper">
                    <div class="static-clients-card" >
                        <div class="static-clients-menu-info">
                            <div class="static-clients-card-info-row">
                                <h6 class="static-cl-card-title card-title">id: {this.state.item.id}</h6>
                                <h6 class="static-cl-card-title card-title">index: {this.state.index}</h6>
                            </div>
                            <div class="static-clients-card-info-row">
                                <div class="static-clients-card-info-half">
                                    <h5 class="static-cl-card-title card-title">{this.state.name}</h5>
                                </div>
                                <div class="static-clients-card-info-half">
                                    <h5 class="static-cl-card-title card-title">Cena: {this.state.price}</h5>
                                </div>
                            </div>
                            <div class="static-clients-card-info-row">
                                <h6 class="static-cl-card-title card-title">Koristi cenu: {this.state.usePrice ? "Da" : "Ne"}</h6>
                            </div>
                            <div class="static-clients-card-info-row">
                                <div class="static-clients-card-info-half">
                                    <h6 class="static-cl-card-title card-title">Da li je RadioButton: {this.state.isRadioButton ? "Da" : "Ne"} </h6>
                                </div>
                                <div class="static-clients-card-info-half">
                                    <h6 class="static-cl-card-title card-title">Prikaži na sajtu: {this.state.showOnWebsite ? "Da" : "Ne"}</h6>
                                </div>
                            </div>
                            <div class="static-clients-card-info-row">
                                <div class="static-clients-card-info-half">
                                    <h6 class="static-cl-card-title card-title">Da li je Exclusive (Iskljucuje neku grupu): {this.state.exclusive ? "Da" : "Ne"} </h6>
                                </div>
                                <div class="static-clients-card-info-half">
                                    <h6 class="static-cl-card-title card-title">Iskljucene grupe: {this.state.excludedGroups}</h6>
                                </div>
                            </div>
                            <div class="static-clients-card-info-row">
                                <div class="static-clients-card-info-half">
                                    <h6 class="static-cl-card-title card-title">Maksimalan broj selektovanih subitema iz grupe (0 - nema ogranicenja): {this.state.maxItemsFromGroup}</h6>
                                </div>
                            </div>
                            <div class="static-clients-card-info-row">
                                <div class="static-clients-card-info-half">
                                    <h6 class="static-cl-card-title card-title">Broj dzaba subitem-a (0 - nema ogranicenja): {this.state.selectionPriceTrigger}</h6>
                                </div>
                            </div>
                            <div class="static-clients-card-info-row">
                                <div class="static-clients-card-info-half">
                                    <h6 class="static-cl-card-title card-title">Grupa: {this.state.group} </h6>
                                </div>
                            </div>
                            <div class="static-clients-card-info-row">
                                <div class="static-clients-card-info-half">
                                <h6 class="static-cl-card-title card-title">Koristi u imenu: {this.state.useInName ? "Da" : "Ne"}</h6>
                                </div>
                            </div>
                        </div>
                        <ButtonsUpDown onClickUp={this.onMoveUp} onClickDown={this.onMoveDown} />
                        <ButtonEditCard onClick={this.onEditClick} />
                        <div class="static-cl-card-right-btn-div">
                            <button onClick={this.copyItemsClick} type="button"
                                className={"btn btn-secondary fixed-width"}>
                                Kopiraj subitem u sve iteme menija
                            </button>
                        </div>
                    </div>
                </div >
            );
        }
        else {
            return (
                <div class="static-clients-menu-wrapper">
                    <div class="static-clients-card" >
                        <div class="static-clients-menu-info">
                            <div class="static-clients-card-info-row">
                                <div class="static-clients-card-info-third">
                                    <div class="static-clients-card-info-imput-wrapper">
                                        <CustomInput inputId="name"
                                            inputValue={this.state.name}
                                            labelValue="Naziv"
                                            placeholder="Naziv"
                                            onChange={this.onInputChange} readOnly={false} />
                                    </div>
                                </div>
                                <div class="static-clients-card-info-tenth">
                                    <h5 class="darksalmon-text"> . </h5>
                                </div>
                                <div class="static-clients-card-info-third">
                                    <div class="static-clients-card-info-imput-wrapper">
                                        <CustomInput inputId="price"
                                            inputValue={this.state.price}
                                            labelValue="Cena"
                                            placeholder=""
                                            onChange={this.onInputChange} readOnly={false} />
                                    </div>
                                </div>
                                <div class="static-clients-card-info-tenth">
                                    <h5 class="darksalmon-text"> . </h5>
                                </div>
                                <ButtonRemoveElement onClick={this.onRemoveElement} />
                            </div>
                            <div class="static-clients-card-info-row">
                                <div class="static-clients-card-info-imput-wrapper">
                                    <CustomInputCheckBox isChecked={this.state.usePrice}
                                        labelValue={"Koristi cenu"}
                                        onChange={this.onUsePriceChange}
                                        value={this.state.usePrice} />
                                </div>
                            </div>
                            <div class="static-clients-card-info-row">
                                <div class="static-clients-card-info-imput-wrapper">
                                    <CustomInputCheckBox isChecked={this.state.isRadioButton}
                                        labelValue={"Da li je RadioButton"}
                                        onChange={this.onIsRadioButtonChange}
                                        value={this.state.isRadioButton} />
                                </div>
                                <div class="static-clients-card-info-imput-wrapper">
                                    <CustomInputCheckBox isChecked={this.state.showOnWebsite}
                                        labelValue={"Prikaži na sajtu"}
                                        onChange={this.onShowOnWebSiteChange}
                                        value={this.state.showOnWebsite} />
                                </div>
                            </div>
                            <div class="static-clients-card-info-row">
                                <div class="static-clients-card-info-imput-wrapper">
                                    <CustomInputCheckBox isChecked={this.state.exclusive}
                                        labelValue={"Exclusive"}
                                        onChange={this.onExclusiveChange}
                                        value={this.state.exclusive} />
                                </div>
                            </div>
                            <div class="static-clients-card-info-row">
                                <div class="static-clients-card-info-imput-wrapper">
                                    <CustomInput inputId="group"
                                        inputValue={this.state.group}
                                        labelValue="Grupa"
                                        placeholder=""
                                        onChange={this.onInputChange} readOnly={false} />
                                </div>
                                <div class="static-clients-card-info-imput-wrapper">
                                    <CustomInput inputId="excludedGroups"
                                        inputValue={this.state.excludedGroups}
                                        labelValue="excludedGroups"
                                        placeholder=""
                                        onChange={this.onInputChange} readOnly={false} />
                                </div>
                            </div>
                            <div class="static-clients-card-info-row">
                                <div class="static-clients-card-info-imput-wrapper">
                                    <CustomInput inputId="maxItemsFromGroup"
                                        inputValue={this.state.maxItemsFromGroup}
                                        labelValue="maxItemsFromGroup"
                                        placeholder=""
                                        onChange={this.onInputChange} readOnly={false} />
                                </div>
                            </div>
                            <div class="static-clients-card-info-row">
                                <div class="static-clients-card-info-imput-wrapper">
                                    <CustomInput inputId="selectionPriceTrigger"
                                        inputValue={this.state.selectionPriceTrigger}
                                        labelValue="broj dzaba subItem-a:"
                                        placeholder=""
                                        onChange={this.onInputChange} readOnly={false} />
                                </div>
                            </div>
                            <div class="static-clients-card-info-row">
                                <div class="static-clients-card-info-imput-wrapper">
                                    <CustomInputCheckBox isChecked={this.state.useInName}
                                        labelValue={"Koristi u imenu"}
                                        onChange={()=> {this.setState({ useInName: !this.state.useInName })}}
                                        value={this.state.useInName} />
                                </div>
                            </div>
                        </div>
                        <ButtonsUpDown onClickUp={this.onMoveUp} onClickDown={this.onMoveDown} />
                        <ButtonSaveCard onClick={() => { this.onSaveClick(true) }} />
                        <ButtonDiscardCard onClick={() => { this.onSaveClick(false) }} />
                    </div>
                </div>
            );
        }
    }
});

export default ClientMenuSubitem;