import React from 'react';
import ReactDOM from 'react-dom';
import './style/index.css';
import App from './App';
import LoginPage from './libs/login_page/login-page.js';
import reportWebVitals from './reportWebVitals';
import AuthenticationManager from './database/authentication.js';
import enumAdminType from './libs/model/enums/adminType';
import EmailVerificationFail from './libs/login_page/email-verification-fail.js';
import AdminVerificationFail from './libs/login_page/admin-verification-fail.js';
import loadingDiv from './libs/libs-common/loading-div.js'
import AutomaticSessionHandler from './database/automatic-session-handler.js';
var createReactClass = require('create-react-class');

var IndexPage = createReactClass({
  getInitialState: function () {
    let _auth_manager = new AuthenticationManager(null, this.handleLogOut);
    _auth_manager.addAuthStateChangeFunction(this.onAuthStateChanged);
    let _automatic_session_handler = new AutomaticSessionHandler(_auth_manager);
    _automatic_session_handler.ForceSessionCheck();

    window.addEventListener("beforeunload", () => {
      _automatic_session_handler.ForceSessionCheck();
    });

    window.addEventListener("mousedown", () => {
      _automatic_session_handler.RenewSession();
    });

    return {
      user: null,
      auth_manager: _auth_manager,
      auth_initialized: false,
      automatic_session_handler: _automatic_session_handler
    }
  },
  onAuthStateChanged: function (user) {
    if (user) {
      this.state.automatic_session_handler.Run();
      this.setState({
        user: user,
        auth_initialized: true
      });
    }
    else {
      this.setState({
        user: null,
        auth_initialized: true
      });
    }
  },
  onLogin: function (user) {
    this.setState({ user: user });
    this.state.automatic_session_handler.Run();
  },
  initiateLogOut: function () {
    this.state.auth_manager.logOut();
  },
  initiateEmailResend: function () {
    this.state.auth_manager.resendEmail();
  },
  handleLogOut: function (error) {
    if (error) {

    }
    else {
      this.setState({ user: null });
    }
  },
  render: function () {
    if (!this.state.auth_initialized) {
      return (loadingDiv());
    }
    else if (this.state.user) {
      let is_user_verified = this.state.auth_manager.isUserVerified(this.state.user);
      if (is_user_verified && this.state.user.type !== enumAdminType.UNIDENTIFIED) {
        return (<App user={this.state.user} logOut={this.initiateLogOut} />);
      }
      else if (!is_user_verified) {
        return (<EmailVerificationFail initiateLogOut={this.initiateLogOut} initiateEmailResend={this.initiateEmailResend} />);
      }
      else if (this.state.user.type === enumAdminType.UNIDENTIFIED) {
        return (<AdminVerificationFail initiateLogOut={this.initiateLogOut} />);
      }
      else {
        return (<AdminVerificationFail initiateLogOut={this.initiateLogOut} />);
      }
    }
    else {
      return (
        <LoginPage onLogin={this.onLogin} />
      )
    }
  }
});

ReactDOM.render(
  <React.StrictMode>
    <IndexPage />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
