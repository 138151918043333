import '../../style/clients-page.css';
import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
var createReactClass = require('create-react-class');
var React = require('react');

var CustomInput = createReactClass({
    render: function () {
        return (
            <div class="form-group">
                <label for={this.props.inputId}>{this.props.labelValue}</label>
                <input type="text"
                    class="form-control"
                    name={this.props.inputId}
                    id={this.props.inputId}
                    placeholder={this.props.placeholder}
                    value={this.props.inputValue}
                    onChange={this.props.onChange}
                    readOnly={this.props.readOnly} />
            </div>
        );
    }
});

export default CustomInput