import '../../style/MessageBox.css';
import createReactClass from 'create-react-class';

var MessageBox = createReactClass({
    componentDidMount() {
        document.body.style.overflow = 'hidden';
    },
    componentWillUnmount() {
        document.body.style.overflow = 'unset';
    },
    render: function () {
        return (
            <div className="message-box-container">
                <div className="message-box-content flex-column border-radius">
                    <div className="message-box-header">
                        <p>{this.props.title}</p>
                    </div>
                    <div className="message-box-body">
                        <p>{this.props.content}</p>
                    </div>
                    <div className="message-box-footer">
                        {this.props.showCancel ?
                            <button className="btn-style-white" onClick={this.props.onCancelClick}>Otkazi</button> : null}
                        <button className="btn-style-white" onClick={this.props.onClick}>U redu</button>
                    </div>
                </div>
            </div>
        )
    }
});

export default MessageBox;