import '../../style/login-page.css';
import AuthenticationManager from '../../database/authentication.js'
var createReactClass = require('create-react-class');
var React = require('react');

var EmailVerificationFail = createReactClass({

    onClick: function (event) {
        switch (event.target.name) {
            case "login_back":
                this.props.initiateLogOut();
                break;
            case "resend_email":
                this.props.initiateEmailResend();
                break;
            default:
                break;
        }
    },
    render: function () {
        return (
            <div class='login-form message'>
                <div class='login-error-message-content-wrap'>
                    <div class="login-error-message-content-caption">
                        <h4>Potvrda e-mail adrese</h4>
                    </div>
                    <div class="login-error-message-content">
                        <p>Vaša registracija je skoro gotova. Da bi je završili, molimo Vas da kliknete na link, koji Vam je upravo poslat na e-mail. </p>
                        <p>Nakon verifikacije, osvežite stranicu.</p>
                        <a id="resend_email" name="resend_email" onClick={this.onClick} className="link-to-terms">Kliknite ovde</a>, ukoliko e-mail za verifikaciju nije pristigao. Molimo Vas da proverite spam.
                        <br />
                        <a id="login_back" name="login_back" onClick={this.onClick} className="link-to-terms">Povratak na 'LogIn' stranicu</a>
                    </div>
                </div>
            </div>
        );
    }
});

export default EmailVerificationFail;