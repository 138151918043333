import '../../style/login-page.css';
import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import AuthenticationManager from '../../database/authentication.js';
import TermsAndConditions from './terms-and-conditions.js';
import DBHandler from '../../database/database-handler-admin.js';
import MessageBox from '../libs-common/MessageBox';
var createReactClass = require('create-react-class');
var React = require('react');

var LoginPage = createReactClass({
    getInitialState: function () {
        let _auth_manager = new AuthenticationManager(this.performLogin, null);
        return {
            register: false,
            email: "",
            password: "",
            password_2: "",
            email_format_ok: true,
            password_pass_criteria: true,
            password_match: true,
            auth_manager: _auth_manager,
            show_terms_and_conditions: false,
            terms_agree: false,
            show_message: false,
            message_title: "",
            message_content: ""
        }
    },
    performLogin: function (user) {
        if (user && user.code && user.message) {
            let db_object = {};
            db_object["email"] = this.state.email;
            db_object["error_code"] = user.code;
            db_object["error_message"] = user.message;

            (new DBHandler).addNewLoginTry(db_object);
            //TODO: Write custom messages according to error code.
            //let message = getMessageFromErrorCode(user.code);
            this.setState({
                show_message: true,
                message_title: "Greška!",
                message_content: user.message
            });
        }
        else if (user && user.id) {
            this.props.onLogin(user);
        }
        else {
            this.setState({
                show_message: true,
                message_title: "Greška!",
                message_content: "Došlo je do greške prilikom logovanja."
            });
        }
    },
    onClick: function (event) {
        switch (event.target.name) {
            case "btnAction":
                if (this.inputValidation()) {
                    if (this.state.register) {
                        this.state.auth_manager.singIn(this.state.email, this.state.password);
                    }
                    else {
                        this.state.auth_manager.logIn(this.state.email, this.state.password);
                    }
                }
                break;
            case "btnChangeMod":
                let register_val = this.state.register;
                this.setState({
                    email: "",
                    password: "",
                    password_2: "",
                    register: !register_val,
                    email_format_ok: true,
                    password_pass_criteria: true,
                    password_match: true,
                    terms_agree: false
                })
                break;
            case "terms":
                this.setState({
                    show_terms_and_conditions: true
                })
                break;
            case "terms_agree":
                this.setState({
                    terms_agree: event.target.checked
                })
                break;
            default:
                break;
        }
    },
    onChange: function (event) {
        let value = event.target.value;
        let input_name = event.target.name;
        this.setState({ [input_name]: value });
    },
    inputValidation: function () {
        let _email_format_ok = true;
        let _password_pass_criteria = true;
        let _password_match = true;

        _email_format_ok = this.validateEmail(this.state.email);

        if (this.state.register) {
            if (this.state.password !== this.state.password_2) {
                _password_match = false;
            }
            else {
                _password_pass_criteria = this.validatePassword(this.state.password);
            }
        }

        this.setState({
            email_format_ok: _email_format_ok,
            password_pass_criteria: _password_pass_criteria,
            password_match: _password_match
        });


        if (_email_format_ok && _password_pass_criteria && _password_match) {
            return true;
        }
        else {
            return false;
        }
    },
    validateEmail: function (email) {
        let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    },
    validatePassword: function (password) {
        //Minimum eight characters, at least one uppercase letter, 
        //one lowercase letter, one number and one special character
        let re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;
        return re.test(password);
    },
    render: function () {
        return (
            <div class='login-form'>
                {this.state.show_terms_and_conditions ?
                    <TermsAndConditions onClose={() => { this.setState({ show_terms_and_conditions: false }) }} /> : null}
                {this.state.show_message ?
                    <MessageBox title={this.state.message_title} content={this.state.message_content}
                        onClick={() => { this.setState({ show_message: false, message_title: "", message_content: "" }) }} /> : null}
                <h1 class='text-align-center'> {this.state.register ? "Registruj se" : "Uloguj se"}</h1>
                <div class='login-form-content'>
                    <div class="form-group">
                        <label for="email">e-mail:</label>
                        <input class={("form-input " + (this.state.email_format_ok ? "" : "red-border"))}
                            id="email" name="email" type="text" placeholder="Unesite vašu e-mail adresu"
                            onChange={this.onChange} value={this.state.email} />
                    </div>
                    <div class="form-group">
                        <label for="password">Lozinka:</label>
                        <input class={("form-input " + (this.state.password_pass_criteria ? "" : "red-border"))}
                            id="password" name="password" type="password"
                            placeholder={("Unesite " + (this.state.register ? "novu " : "") + "lozinku")}
                            onChange={this.onChange} value={this.state.password} />
                        {this.state.register ? <>  <i class="icon-info-sign">i</i>
                            <span class="extra-info">
                                Lozinka mora sadržati:
                                <br />- najmanje 8 karaktera,
                                <br />- bar jedan specijalni karakter,
                                <br />- bar jedno veliko slovo,
                                <br />- bar jedan broj.
                            </span> </> : null}
                    </div>
                    {this.state.register ?
                        <>
                            <div class="form-group">
                                <label for="password">Ponovite lozinku:</label>
                                <input class={("form-input " + (this.state.password_match ? "" : "red-border"))}
                                    id="password_2" name="password_2" type="password" placeholder="Ponovite lozinku"
                                    onChange={this.onChange} value={this.state.password_2} />
                            </div>
                            <div class="form-group">
                                <input type="checkbox" id="terms_agree" name="terms_agree" onClick={this.onClick} />
                                <label for="terms_agree" class="label custom-checkbox">Slažem se sa </label>
                                <a id="terms" name="terms" onClick={this.onClick} className="link-to-terms"> uslovima korišćenja ovog portala.</a>
                            </div>
                        </>
                        : null}
                    <div class="form-group">
                        {!this.state.register ?
                            <><input type="checkbox" id="remember_me" name="remember_me" />
                                <label for="remember_me" class="label custom-checkbox">Zapamti me</label></> : null}
                        <button onClick={this.onClick} name="btnAction" class="btn-login"
                            disabled={(this.state.register & !this.state.terms_agree) ? true : false}>
                            {this.state.register ? "Registruj se" : "Uloguj se"}
                        </button>
                        <button onClick={this.onClick} name="btnChangeMod" class="btn-login register">
                            {this.state.register ? "Logovanje" : "Registracija"}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
});

export default LoginPage;

