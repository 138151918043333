import '../../style/clients-page.css';
import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
var createReactClass = require('create-react-class');
var React = require('react');

var ButtonsUpDown = createReactClass({
    render: function () {
        return (
            <div class="static-cl-card-buttons-up-down">
                <button onClick={this.props.onClickUp} type="button"
                    className={"btn btn-secondary"}>
                    <i class="arrow up"></i>
                </button>
                <br />
                <button onClick={this.props.onClickDown} type="button"
                    className={"btn btn-secondary"}>
                    <i class="arrow down"></i>
                </button>
            </div>
        );
    }
});

export default ButtonsUpDown;