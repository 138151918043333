/* eslint-disable react/no-direct-mutation-state */
import '../../style/clients-page.css';
import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import CustomDropdown from '../libs-common/custom-dropdown.js';
import ButtonDiscardCard from '../libs-common/button-discard.js';
import ButtonAddElement from '../libs-common/button-add-element.js';
import ButtonRemoveElement from '../libs-common/button-remove-element.js';
import ButtonEditCard from '../libs-common/button-edit-card.js';
import ButtonSaveCard from '../libs-common/button-save-card.js';
import ButtonsUpDown from '../libs-common/buttons-up-down.js';
import CustomInputCheckBox from '../libs-common/custom-check-box.js';
import CustomInput from '../libs-common/custom-input.js';
import ClientMenuItem from './static-client-menu-item.js';
var createReactClass = require('create-react-class');
var React = require('react');

var ClientMenu = createReactClass({
    getInitialState: function () {
        this.props.dbHandler.addReloadMethod(this.reloadVariables);
        if (this.props.menu) {
            return {
                show_more: false,
                show_children: false,
                client: this.props.client,
                menu: this.props.menu,
                index: this.props.menu.index,
                showOnWebsite: this.props.menu.showOnWebsite,
                name: this.props.menu.name,
                icon: this.props.menu.icon,
                items: this.props.menu.items,
                remove_menu: false,
                useMeInOtherItems: this.props.menu.useMeInOtherItems,
            }
        }
        else {
            return null;
        }
    },
    componentDidUpdate(prevProps) {
        if (this.props.menu && this.props.menu.index !== prevProps.menu.index) {
            this.reloadVariables();
        }
    },
    reloadVariables: function () {
        if (this.props.menu) {
            this.setState({
                client: this.props.client,
                menu: this.props.menu,
                index: this.props.menu.index,
                showOnWebsite: this.props.menu.showOnWebsite,
                name: this.props.menu.name,
                icon: this.props.menu.icon,
                items: this.props.menu.items,
                useMeInOtherItems: this.props.menu.useMeInOtherItems,
            });
        }

    },
    onEditClick: function () {
        let new_state = !this.state.show_more;
        this.setState({ show_more: new_state });
    },
    onSaveClick: function (save) {
        if (save) {
            let new_state = !this.state.show_more;
            this.setState({ show_more: new_state });
            this.state.menu.name = this.state.name;
            this.state.menu.showOnWebsite = this.state.showOnWebsite;
            this.state.menu.icon = this.state.icon;
            this.state.menu.useMeInOtherItems = this.state.useMeInOtherItems;
            console.log(this.props.client.id);
            console.log(this.state.menu);
            this.props.dbHandler.addOrUpdateMenuDB(this.props.client.id, this.state.menu);
        }
        else {
            let new_state = !this.state.show_more;
            this.setState({ show_more: new_state });

            this.props.dbHandler.getMenusFromClientDB(this.props.client);
        }
        this.reloadVariables();
    },
    showChildrenClick: function () {
        let new_state = !this.state.show_children;
        this.setState({ show_children: new_state });
    },
    onInputChange: function (event) {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({ [nam]: val });
    },
    onShowOnWebSiteChange: function () {
        let _showOnWebsite = this.state.showOnWebsite;
        this.setState({ showOnWebsite: !_showOnWebsite })
    },
    onDropdownChange: function (new_value) {
        this.setState({ icon: new_value });
    },
    onRemoveElement() {
        this.props.dbHandler.removeMenuFromDB(this.props.client, this.state.menu);
        let _remove_menu = !this.state.remove_menu;
        this.setState({ remove_menu: _remove_menu });
        // this.state.client.removeMenuById(this.state.menu.id);
    },
    onMoveUp: function () {
        this.props.dbHandler.moveMenuUp(this.state.client, this.state.index)
        this.setState({
            index: this.state.menu.index
        })
    },
    onMoveDown: function () {
        this.props.dbHandler.moveMenuDown(this.state.client, this.state.index)
        this.setState({
            index: this.state.menu.index
        })
    },
    generateMenuItems: function () {
        let items = [];
        for (var key in this.state.items) {
            if (this.state.items[key]) {
                items.push(<ClientMenuItem item={this.state.items[key]}
                    menu={this.state.menu}
                    client={this.props.client}
                    dbHandler={this.props.dbHandler} />);
            }
        }
        return items;
    },
    addNewElement: function () {
        this.state.menu.addNewItem();
        this.props.dbHandler.addOrUpdateMenuDB(this.props.client.id, this.props.menu);
        this.setState({ items: this.state.menu.items })
    },
    onDbTransactionFinish: function () {
        this.reloadVariables();
    },
    render: function () {
        if (this.state.remove_menu) { return null; }
        if (!this.state.show_more) {
            return (
                <div class="static-clients-menu-wrapper">
                    <div class="static-clients-card" >
                        <div class="static-clients-menu-info">
                            <div class="static-clients-card-info-row">
                                <h6 class="static-cl-card-title card-title">id: {this.state.menu.id}</h6>
                                <h6 class="static-cl-card-title card-title">index: {this.state.index}</h6>
                            </div>
                            <div class="static-clients-card-info-row">
                                <div class="static-clients-card-info-half">
                                    <h5 class="static-cl-card-title card-title">{this.state.name}</h5>
                                </div>
                                <div class="static-clients-card-info-half">
                                    <h5 class="static-cl-card-title card-title">Sličica: {this.state.icon}</h5>
                                </div>
                            </div>
                            <div class="static-clients-card-info-row">
                                <div class="static-clients-card-info-half">
                                    <h6 class="static-cl-card-title card-title">Prikaži na sajtu: {this.state.showOnWebsite ? "Da" : "Ne"}</h6>
                                </div>
                            </div>
                            <div class="static-clients-card-info-row">
                                <div class="static-clients-card-info-half">
                                    <h6 class="static-cl-card-title card-title">Prikazi me u drugim menijima: {this.state.useMeInOtherItems ? "Da" : "Ne"}</h6>
                                </div>
                            </div>
                        </div>
                        <ButtonsUpDown onClickUp={this.onMoveUp} onClickDown={this.onMoveDown} />
                        <ButtonEditCard onClick={this.onEditClick} />
                    </div>
                    <div class="static-cl-card-show-children">
                        {this.state.show_children ?
                            <div class="static-cl-card-show-children-content">
                                {this.generateMenuItems()}
                                <div class="static-clients-menu-wrapper">
                                    <ButtonAddElement onClick={this.addNewElement} />
                                </div>
                                <div class="static-cl-card-show-children-space">
                                    <h5 class="white-text"> . </h5>
                                </div>
                            </div>
                            : null}
                        <div class="static-cl-card-show-children-button" onClick={this.showChildrenClick}>
                            <i class={"arrow" + (this.state.show_children ? " up" : " down")}></i>
                        </div>
                    </div>
                </div >
            );
        }
        else {
            return (
                <div class="static-clients-menu-wrapper">
                    <div class="static-clients-card" >
                        <div class="static-clients-menu-info">
                            <div class="static-clients-card-info-row">
                                <div class="static-clients-card-info-third">
                                    <div class="static-clients-card-info-imput-wrapper">
                                        <CustomInput inputId="name"
                                            inputValue={this.state.name}
                                            labelValue="Naziv menija"
                                            placeholder="Naziv menija"
                                            onChange={this.onInputChange} readOnly={false} />
                                    </div>
                                </div>
                                <div class="static-clients-card-info-tenth">
                                    <h5 class="darksalmon-text"> . </h5>
                                </div>
                                <div class="static-clients-card-info-third">
                                    <div class="static-clients-card-info-imput-wrapper">
                                        <CustomDropdown selectedValue={this.state.icon}
                                            labelValue={"Simbol"}
                                            dropdownId="menu_icon_dropdown"
                                            items={[]}
                                            onChange={this.onDropdownChange} />
                                    </div>
                                </div>
                                <div class="static-clients-card-info-tenth">
                                    <h5 class="darksalmon-text"> . </h5>
                                </div>
                                <ButtonRemoveElement onClick={this.onRemoveElement.bind(this)} />
                            </div>
                            <div class="static-clients-card-info-row">
                                <div class="static-clients-card-info-imput-wrapper">
                                    <CustomInputCheckBox isChecked={this.state.showOnWebsite}
                                        labelValue={"Prikaži na sajtu"}
                                        onChange={this.onShowOnWebSiteChange}
                                        value={this.state.showOnWebsite} />
                                </div>
                            </div>
                            <div class="static-clients-card-info-row">
                                <div class="static-clients-card-info-imput-wrapper">
                                    <CustomInputCheckBox isChecked={this.state.useMeInOtherItems}
                                        labelValue={"Prikazi me u drugim menijima:"}
                                        onChange={()=>{this.setState({ useMeInOtherItems: !this.state.useMeInOtherItems })}}
                                        value={this.state.useMeInOtherItems} />
                                </div>
                            </div>
                        </div>
                        <ButtonsUpDown onClickUp={this.onMoveUp} onClickDown={this.onMoveDown} />
                        <ButtonSaveCard onClick={this.onSaveClick.bind(this, true)} />
                        <ButtonDiscardCard onClick={this.onSaveClick.bind(this, false)} />
                    </div>
                    <div class="static-cl-card-show-children">
                        {this.state.show_children ?
                            <div class="static-cl-card-show-children-content">
                                {this.generateMenuItems()}
                                <div class="static-clients-menu-wrapper">
                                    <ButtonAddElement onClick={this.addNewElement} />
                                </div>
                                <div class="static-cl-card-show-children-space">
                                    <h5 class="white-text"> . </h5>
                                </div>
                            </div>
                            : null}
                        <div class="static-cl-card-show-children-button" onClick={this.showChildrenClick}>
                            <i class={"arrow" + (this.state.show_children ? " up" : " down")}></i>
                        </div>
                    </div>
                </div>
            );
        }
    }
});

export default ClientMenu;