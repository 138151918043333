/* eslint-disable require-jsdoc */
import Image from "./image";

/**
 * Placeholder class for Address attributes.
 */
export class ClientType {
  index: number;
  name: string;
  img: Image;
  headerImg: Image;
  sideMenuImg: Image;
  id: string;
  /**
   * Empty Constructor
   */
  constructor() {
    this.index = 0;
    this.name = "";
    this.img = new Image();
    this.headerImg = new Image();
    this.sideMenuImg = new Image();
    this.id = "";
  }

  loadFromJson(jsonObject: any) {
    if (jsonObject) {
      if (jsonObject.index) {
        this.index = jsonObject.index;
      }
      if (jsonObject.name) {
        this.name = jsonObject.name;
      }
      if (jsonObject.img) {
        let img = new Image();
        img.loadFromJson(jsonObject.img);
        this.img = img;
      }
      if (jsonObject.headerImg) {
        let img = new Image();
        img.loadFromJson(jsonObject.headerImg);
        this.headerImg = img;
      }
      if (jsonObject.sideMenuImg) {
        let img = new Image();
        img.loadFromJson(jsonObject.headerImg);
        this.headerImg = img;
      }
      if (jsonObject.id) {
        this.id = jsonObject.id;
      }
    }
  }

  toJason(): any {
    return {
      index: this.index,
      name: this.name,
      img: this.img.toJason(),
      headerImg: this.headerImg.toJason(),
      sideMenuImg: this.sideMenuImg.toJason(),
      id: this.id,
    };
  }
}

export default ClientType;
