import '../../style/clients-page.css';
import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
var createReactClass = require('create-react-class');
var React = require('react');

var InputWorkTime = createReactClass({
    onChange: function (event) {
        let args = event.target.name.split('_');
        let name = args[0];
        let index = parseInt(args[1]);
        let val = event.target.value;

        switch (name) {
            case "startHours":
                this.props.client.workingTime[index].start_hours = val;
                this.setState({ [name]: val });
                break;
            case "startMinutes":
                this.props.client.workingTime[index].start_minutes = val;
                this.setState({ [name]: val });
                break;
            case "endHours":
                this.props.client.workingTime[index].end_hours = val;
                this.setState({ [name]: val });
                break;
            case "endMinutes":
                this.props.client.workingTime[index].end_minutes = val;
                this.setState({ [name]: val });
                break;
            case "dStartHours":
                this.props.client.workingTime[index].delivery_start_hours = val;
                this.setState({ [name]: val });
                break;
            case "dStartMinutes":
                this.props.client.workingTime[index].delivery_start_minutes = val;
                this.setState({ [name]: val });
                break;
            case "dEndHours":
                this.props.client.workingTime[index].delivery_end_hours = val;
                this.setState({ [name]: val });
                break;
            case "dEndMinutes":
                this.props.client.workingTime[index].delivery_end_minutes = val;
                this.setState({ [name]: val });
                break;
            case "buttonDays":
                let workingTime_index = parseInt(args[1]);
                let day_index = parseInt(args[2]);
                let current_val = this.props.client.workingTime[workingTime_index].applied_days[day_index];
                this.props.client.workingTime[workingTime_index].applied_days[day_index] = !current_val;
                this.setState({ atr: "c" }); //Just to call Render
                break;
            case "buttonAdd":
                this.props.client.addNewWorkingTime();
                this.setState({ atr: "b" });
                break;
            case "buttonRemove":
                this.props.client.removeWorkingTimeAtIndex(index);
                this.setState({ atr: "a" });
                break;
            default:
                break;
        }
        //this.props.onChange(); Enable this if it's needed for Save option.
    },
    getButtonsForWorkingTime: function (workingTime, index) {
        let buttons = [];
        for (let button_index = 0; button_index < 7; button_index++) {
            buttons.push(
                <button name={"buttonDays_" + index + "_" + button_index}
                    class={("btn button_working_days" + (workingTime.applied_days[button_index] ? " enabled" : ""))}
                    onClick={this.onChange}>
                    {this.getDayNameByNum(button_index)}
                </button>);
        }
        if ((index !== 0) && (this.props.client.workingTime.length !== 0)) {
            buttons.push(
                <button name={"buttonRemove_" + index}
                    class={("btn button_working_days red")}
                    onClick={this.onChange}>
                    -
                </button>
            );
        }
        if ((this.props.client.workingTime.length - 1 === index) ||
            (this.props.client.workingTime.length === 0)) {
            buttons.push(
                <button name={"buttonAdd_" + index}
                    class={("btn button_working_days green")}
                    onClick={this.onChange}>
                    +
                </button>
            );
        }

        return buttons
    },
    getDayNameByNum: function (num) {
        switch (num) {
            case 0:
                return "Pon";
            case 1:
                return "Uto";
            case 2:
                return "Sre";
            case 3:
                return "Čet";
            case 4:
                return "Pet";
            case 5:
                return "Sub";
            case 6:
                return "Ned";
            default:
                return "";
        }
    },
    render: function () {
        return (
            <>

                {this.props.client.workingTime.map((workingTime, index) => {
                    return (
                        <>
                            <div class="static-clients-card-info-row">
                                <label for="">{this.props.labelValue + " " + (index + 1) + "."}</label>
                            </div>
                            <div class="static-clients-card-info-row ">
                                <div class="static-clients-card-info-sixtieth">
                                    <div class="static-clients-card-info-five-p working-time-label">
                                        <label>od: </label>
                                    </div>
                                    <div class="static-clients-card-info-twentieth">
                                        <input type="number" class="form-control number" min="0" max="24"
                                            name={"startHours_" + index}
                                            id={"startHours_" + index}
                                            onChange={this.onChange}
                                            value={workingTime.start_hours} />
                                    </div>
                                    <div class="static-clients-card-info-five-p working-time-label">
                                        <label>:</label>
                                    </div>
                                    <div class="static-clients-card-info-twentieth">
                                        <input type="number" class="form-control number" min="0" max="59"
                                            name={"startMinutes_" + index}
                                            id={"startMinutes_" + index}
                                            onChange={this.onChange}
                                            value={workingTime.start_minutes} />
                                    </div>
                                    <div class="static-clients-card-info-five-p working-time-label">
                                        <label> do: </label>
                                    </div>
                                    <div class="static-clients-card-info-twentieth">
                                        <input type="number" class="form-control number" min="0" max="24"
                                            name={"endHours_" + index}
                                            id={"endHours_" + index}
                                            onChange={this.onChange}
                                            value={workingTime.end_hours} />
                                    </div>
                                    <div class="static-clients-card-info-five-p working-time-label">
                                        <label>:</label>
                                    </div>
                                    <div class="static-clients-card-info-twentieth">
                                        <input type="number" class="form-control number" min="0" max="59"
                                            name={"endMinutes_" + index}
                                            id={"endMinutes_" + index}
                                            onChange={this.onChange}
                                            value={workingTime.end_minutes} />
                                    </div>
                                </div>
                                <div class="static-clients-card-info-fortieth">
                                    {this.getButtonsForWorkingTime(workingTime, index)}
                                </div>
                            </div>
                            <div class="static-clients-card-info-row">
                                <label for="">Vreme dostave</label>
                            </div>
                            <div class="static-clients-card-info-row">
                                <div class="static-clients-card-info-sixtieth">
                                    <div class="static-clients-card-info-five-p working-time-label">
                                        <label>od: </label>
                                    </div>
                                    <div class="static-clients-card-info-twentieth">
                                        <input type="number" class="form-control number" min="0" max="24"
                                            name={"dStartHours_" + index}
                                            id={"dStartHours_" + index}
                                            onChange={this.onChange}
                                            value={workingTime.delivery_start_hours} />
                                    </div>
                                    <div class="static-clients-card-info-five-p working-time-label">
                                        <label>:</label>
                                    </div>
                                    <div class="static-clients-card-info-twentieth">
                                        <input type="number" class="form-control number" min="0" max="59"
                                            name={"dStartMinutes_" + index}
                                            id={"dStartMinutes_" + index}
                                            onChange={this.onChange}
                                            value={workingTime.delivery_start_minutes} />
                                    </div>
                                    <div class="static-clients-card-info-five-p working-time-label">
                                        <label> do: </label>
                                    </div>
                                    <div class="static-clients-card-info-twentieth">
                                        <input type="number" class="form-control number" min="0" max="24"
                                            name={"dEndHours_" + index}
                                            id={"dEndHours_" + index}
                                            onChange={this.onChange}
                                            value={workingTime.delivery_end_hours} />
                                    </div>
                                    <div class="static-clients-card-info-five-p working-time-label">
                                        <label>:</label>
                                    </div>
                                    <div class="static-clients-card-info-twentieth">
                                        <input type="number" class="form-control number" min="0" max="59"
                                            name={"dEndMinutes_" + index}
                                            id={"dEndMinutes_" + index}
                                            onChange={this.onChange}
                                            value={workingTime.delivery_end_minutes} />
                                    </div>
                                </div>
                            </div>
                        </>
                    );
                })}
            </>
        );
    }
});

export default InputWorkTime