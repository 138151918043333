import { Client } from "./client";
import { WorkingTime } from "./workingTime";

export class Clients {
    private _children: { [id: string]: Client; };

    constructor() {
        this._children = {};
    }

    get children(): { [id: number]: Client; } {
        return this._children;
    }
    set children(val: { [id: number]: Client; }) {
        this._children = val;
    }

    addClient(client: Client) {
        if (!this._children[client.id]) {
            this._children[client.id] = client;
        }
    }

    addNewClient() {
        let keyHashSet: { [id: number]: boolean } = {}
        Object.values(this._children).forEach(client => {
            keyHashSet[client.index] = true;
        });
        console.log(keyHashSet);

        let index: number = 0;
        for (let counter = 0; counter <= Object.keys(keyHashSet).length; counter++) {
            if (!keyHashSet[counter]) {
                index = counter;
            }
        }
        console.log(index);

        let client: Client = new Client();
        client.index = index;
        client.name = "Novi lokal"
        this._children[index] = client;

        console.log(client);

        return client;
    }

    removeClient(client: Client) {
        // let index_to_remove = client.index;
        // for (let index = 0; index < Object.keys(this._children).length; index++) {
        //     if (index > index_to_remove) {
        //         this._children[index - 1] = this._children[index];
        //         this._children[index - 1].index = index - 1;
        //     }
        // }
        // delete this._children[Object.keys(this._children).length - 1];
    }

    moveUp(index_to_move: number) {
        // if (index_to_move === 0) {
        //     return;
        // }
        // let upper_element = this._children[index_to_move - 1];
        // let selected_element = this._children[index_to_move];
        // this._children[index_to_move] = upper_element;
        // this._children[index_to_move - 1] = selected_element;
        // this._children[index_to_move].index = index_to_move;
        // this._children[index_to_move - 1].index = index_to_move - 1;
    }

    moveDown(index_to_move: number) {
        // if ((Object.keys(this._children).length - 1) === index_to_move) {
        //     return;
        // }
        // console.log(this._children);

        // let down_element = this._children[index_to_move + 1];
        // let selected_element = this._children[index_to_move];
        // this._children[index_to_move] = down_element;
        // this._children[index_to_move + 1] = selected_element;
        // this._children[index_to_move].index = index_to_move;
        // this._children[index_to_move + 1].index = index_to_move + 1;
    }
}

export default Clients