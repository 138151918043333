import Image from "./image";
import MenuItem from "./menuItem";

export class Menu {
    private _id: string;
    private _index: number;
    private _showOnWebsite: boolean;
    private _name: string;
    private _icon: string;
    private _items: { [id: number]: MenuItem; };
    private _img: Image;
    private _useMeInOtherItems: boolean

    constructor();
    constructor(index: number, showOnWebsite: boolean, name: string, icon: string);
    constructor(index?: number, showOnWebsite?: boolean, name?: string, icon?: string) {
        this._index = index !== undefined ? index : 0;
        this._showOnWebsite = showOnWebsite !== undefined ? showOnWebsite : false;
        this._name = name !== undefined ? name : "";
        this._icon = icon !== undefined ? icon : "";
        this._items = {};
        this._id = "";
        this._img = new Image();
        this._useMeInOtherItems = false;
    }

    get id(): string {
        return this._id;
    }
    set id(value: string) {
        this._id = value;
    }

    get index(): number {
        return this._index;
    }
    set index(value: number) {
        this._index = value;
    }

    get showOnWebsite(): boolean {
        return this._showOnWebsite;
    }
    set showOnWebsite(value: boolean) {
        this._showOnWebsite = value;
    }

    get name(): string {
        return this._name;
    }
    set name(value: string) {
        this._name = value;
    }

    get icon(): string {
        return this._icon;
    }
    set icon(value: string) {
        this._icon = value;
    }

    get img(): Image {
        return this._img;
    }
    set img(value: Image) {
        this._img = value;
    }

    get items(): { [id: number]: MenuItem; } {
        return this._items;
    }
    set items(value: { [id: number]: MenuItem; }) {
        this._items = value;
    }
    
    get useMeInOtherItems(): boolean {
        return this._useMeInOtherItems;
    }
    set useMeInOtherItems(value: boolean) {
        this._useMeInOtherItems = value;
    }

    addItem(item: MenuItem) {
        this._items[item.index] = item;
    }

    addNewItem() {
        let index: number = Object.keys(this._items).length;
        let item: MenuItem = new MenuItem(index, false, "Novi element", "Opis", "", 0);
        if (this.items[index]) {
            let newIndex = index;
            while (this.items[newIndex]) {
                newIndex++;
            }
            index = newIndex;
        }
        item.name = "Novi element " + index;
        this._items[index] = item;
        return item;
    }

    removeItem(item: MenuItem) {
        let index_to_remove = item.index;
        for (let index = 0; index < Object.keys(this._items).length; index++) {
            if (index > index_to_remove) {
                this._items[index - 1] = this._items[index];
                if (this._items[index - 1]) {
                    this._items[index - 1].index = index - 1;
                }
            }
        }
        delete this._items[Object.keys(this._items).length - 1];
    }

    moveUp(index_to_move: number) {
        if (index_to_move === 0) {
            return;
        }
        let upper_element = this._items[index_to_move - 1];
        let selected_element = this._items[index_to_move];
        this._items[index_to_move] = upper_element;
        this._items[index_to_move - 1] = selected_element;
        if (this._items[index_to_move]) {
            this._items[index_to_move].index = index_to_move;
        }
        if (this._items[index_to_move - 1]) {
            this._items[index_to_move - 1].index = index_to_move - 1;
        }
    }

    moveDown(index_to_move: number) {
        if ((Object.keys(this._items).length - 1) === index_to_move) {
            return;
        }
        let down_element = this._items[index_to_move + 1];
        let selected_element = this._items[index_to_move];
        this._items[index_to_move] = down_element;
        this._items[index_to_move + 1] = selected_element;
        if (this._items[index_to_move]) {
            this._items[index_to_move].index = index_to_move;
        }
        if (this._items[index_to_move + 1]) {
            this._items[index_to_move + 1].index = index_to_move + 1;
        }
    }

    toJson() {
        let items_json: Array<{}> = [];
        Object.values(this._items).forEach((item: MenuItem) => {
            items_json.push(item.toJson());
        });
        return {
            id: this._id,
            index: this._index,
            showOnWebsite: this._showOnWebsite,
            name: this._name,
            icon: this._icon,
            img: this.img.toJason(),
            items: items_json,
            useMeInOtherItems: this._useMeInOtherItems,
        }
    }

    loadFromJson(jsonObject: any) {
        if (jsonObject) {
            if (jsonObject.id) {
                this.id = jsonObject.id;
            }
            if (jsonObject.index) {
                this.index = jsonObject.index;
            }
            if (jsonObject.showOnWebsite) {
                this._showOnWebsite = jsonObject.showOnWebsite;
            }
            if (jsonObject.name) {
                this._name = jsonObject.name;
            }
            if (jsonObject.icon) {
                this._icon = jsonObject.icon;
            }
            if (jsonObject.img) {
                let img = new Image();
                img.loadFromJson(jsonObject.img);
                this.img = img;
            }
            if (jsonObject.items) {
                this._items = [];
                jsonObject.items.forEach((itemJson: {}) => {
                    const itemObj = new MenuItem();
                    itemObj.loadFromJson(itemJson);
                    this.addItem(itemObj);
                });
            }
            if(jsonObject.useMeInOtherItems){
                this._useMeInOtherItems = jsonObject.useMeInOtherItems;
            }
        }
    }
}

export default Menu