import '../../style/login-page.css';
import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import MessageBox from '../libs-common/MessageBox';
var createReactClass = require('create-react-class');
var React = require('react');

var TermsAndConditions = createReactClass({
    generateContent: function () {
        let content = <div class="terms-and-conditions-wrapper">
            <div class="terms-and-conditions">
                <p>Dobrodošli na demango.rs interni portal!</p>

                <p>Ovim uslovima korišćenja su definisana paravila za korišćenje aplikacija sadržanih u portalu na domenu internal-portal-demango.rs</p>

                <p>Prihvatanjem ovih pravila smatramo da ste saglasni sa njima.
                    Molimo vas, nemojte koristiti sadržaj portal ukoliko niste u potpunosti saglasni sa svakom stavkom ovih pravila.</p>

                <p>
                    U narednom tekstu, termin "korisnik", odnosiće se na osobu koja koristi ovaj portal i na koju se primenjuju ova
                    pravila korišćenja.
                    Pod terminom "klijent" se smatra svako fizičko ili pravno lice koje koristi usluge demango.rs platforme.
                    Administrator je osoba koja ima potpuno pravo pristupa platformi i bavi se poboljšanjem njenih usluga, kao i
                    korisničkom/klijentskom podrškom.
                    Platforma u narednom tekstu predstavlja skup aplikacija koje medjusobno korespondiraju i omogućavaju korisniku i klijentu
                    lakse obavljanje procesa dostave. Podeljena je po zonama, od kojih su neke ograničene za pristup definisanim permisijama.
                    Permisija predstavlja pravo pristupa određenoj zoni platforme i dodeljuje se od strane administratora.
                </p>

                {/*
        The following terminology applies to these Terms and Conditions, 
        Privacy Statement and Disclaimer Notice and all Agreements: “Client”, “You” and “Your” refers to you, 
        the person log on this website and compliant to the Company's terms and conditions. 
        “The Company”, “Ourselves”, “We”, “Our” and “Us”, refers to our Company. 
        “Party”, “Parties”, or “Us”, refers to both the Client and ourselves. 
        All terms refer to the offer, acceptance and consideration of payment necessary to undertake the process of our assistance 
        to the Client in the most appropriate manner for the express purpose of meeting the Client's needs in respect of 
        provision of the Company's stated services, in accordance with and subject to, prevailing law of Netherlands. 
        Any use of the above terminology or other words in the singular, plural, capitalization and/or he/she or they, are 
        taken as interchangeable and therefore as referring to same.
        

        License
        Unless otherwise stated, Company Name and/or its licensors own the intellectual property rights for all material on Website Name. 
        All intellectual property rights are reserved. You may access this from Website Name for your own personal use subjected to 
        restrictions set in these terms and conditions.

        */ }

                <h6>Kolačići</h6>
                <p>
                    Vašim pristupom ovom portalu smatra se da ste saglasni sa korišćenjem kolačića koje ovaj portal koristi u cilju: poboljšanja
                    vizualnog utiska sajta, diagnostike, boljih performansi i bezbednosti.
                </p>
                <h6>Licenca</h6>
                <p>
                    /Ime firme/ zadržava sva  prava na intelektualnu svojinu portala internal-portal-demango.rs, kao i celokupne platforme.
                    Korisnik ima pravo korišćenja sadržaja platforme u skladu sa dodeljenim permisijama.
                </p>
                <h6>Permisije</h6>
                <p>
                    Administratori portala dodeljuju svakom individualnom korisniku set permisija koje odobravaju, odnosno zabranjuju pristup
                    odredjenim stranicama i aplikacijama portala.
                </p>
                <p>
                    U slučaju nedostatka određenih permisija, korisnik treba da se obrati administratorima portala.
                </p>
                <h6>Korisnički nalozi</h6>
                <p>
                    Prihvatanjem ovih pravila i završavanjem procedure kreiranja korisničkog naloga, korisnik će biti u mogućnosti da
                    koristi portal u skladu sa definisanim permisijama.
                </p>
                <p>
                    Svaki korisnički nalog se odnosi isključivo na korisnika koji je kreirao isti i strogo je zabranjeno razmenjivanje korisničkih
                    naloga sa drugim licima! Podatke za logovanje na portal sme da zna samo korisnik koji je i kreirao taj nalog.
                </p>
                <h6>Klijenti i njihovi podaci</h6>
                <p>
                    Na ovom portalu se nalaze lični podaci klijenata koji koriste usluge platforme sa domena demango.rs.
                    Korisnik, koji ima uvid u  ove podatke, je dužan da brine o bezbednosti istih.
                </p>
                <p>
                    Strogo je zabranjeno prosleđivanje ovakvih podataka bilo kojem privatnom ili pravnom licu koje nije korisnik portala ili kojem
                    administratori nisu dodelili odgovarajuce permisije!
                    Svaki prenos ličnih podataka korisnika mora se vršiti isključivo preko portala.
                </p>

                <h6>Softverske greške - Bagovi</h6>
                <p>
                    Bag (eng. Bug) je neželjeno ponasanje softvera, odnosno bilo koje aplikacije na portalu.
                    Osnovna klasifikacija grešaka je po veličini problema koje one uzrokuju, pa se u toj podeli kreću od estetskih
                    do grešaka koje blokiraju određenu funkcionalnost aplikacije i portala.
                    Klasifikacija bagova biće detaljnije opisana u formi za prijavu istih.
                </p>
                <p>
                    Svaki korisnik koji uoči bag, dužan je da ga prijavi administratorima prortala putem forme za prijavu.
                </p>
                <p>
                    Svako korišćenje ovih grešaka, koje određenom korisniku mogu dozvoliti funkcionalnosti portala koje nisu
                    dozvoljene dodeljenim permisijama, u cilju počinjavanja bilo kakve štete ili dobavljanja informacija o klijentima,
                    strogo je zabranjeno!
                </p>
                <p>
                    /Ime firme/ se obavezuje da će svaki prijavljeni problem rešiti u najkraćem mogućem roku.
                </p>

                <h6>Verzija softvera i unapređivanje</h6>
                <p>
                    Platforma se nalazi u fazi razvoja tokom koje će /Ime firme/ raditi na dodavanju novih funkcionalnosti, kao i
                    na unapređivanju postojećih. Svaki razvoj i unapređivanje, biće definisan verzijom.
                </p>
                <p>
                    Tokom predstojećeg razvoja, sasvim je moguća pojava bagova i, kao sto je u tekstu već rečeno, /Ime firme/
                    će se potruditi da se isti uklone u najskorijem vremenu. Isto tako, /Ime firme/ neće snositi odgovornost
                    za štetu nastalu manifestovanim bagovima dokle god je platforma u fazi razvoja.
                </p>
                <p>
                    /Ime firme/ će, tokom redovnog unapređivanja platforme, imati potrebu da na kratko ugasi sve ili pojedine
                    aplikacije i funkcionalnosti. Ovakve radnje će biti blagovremeno najavljene korisnicima i desiće se u periodu
                    kada se platforma najmanje koristi.
                </p>
                <h6>Izmene pravila korišćenja</h6>
                <p>
                    /Ime firme/ zadržava pravo da pravila korišćenja izmeni u cilju poboljšanja poslovanja. Za svaku izmenu
                    ovih pravila, vlasnici platforme su dužni da obaveste korisnike portala i dozvole im da se opet izjasne da li
                    se sa izmenama slažu.
                </p>
                <h6>Hvala na izdvojenom vremenu i poverenju!</h6>
                {/*
        You must not:
        
        Republish material from Website Name
        Sell, rent or sub-license material from Website Name
        Reproduce, duplicate or copy material from Website Name
        Redistribute content from Website Name
        This Agreement shall begin on the date hereof.
        
        Parts of this website offer an opportunity for users to post and exchange opinions and information in certain areas of the website. Company Name does not filter, edit, publish or review Comments prior to their presence on the website. Comments do not reflect the views and opinions of Company Name,its agents and/or affiliates. Comments reflect the views and opinions of the person who post their views and opinions. To the extent permitted by applicable laws, Company Name shall not be liable for the Comments or for any liability, damages or expenses caused and/or suffered as a result of any use of and/or posting of and/or appearance of the Comments on this website.
        
        Company Name reserves the right to monitor all Comments and to remove any Comments which can be considered inappropriate, offensive or causes breach of these Terms and Conditions.
        
        You warrant and represent that:
        
        You are entitled to post the Comments on our website and have all necessary licenses and consents to do so;
        The Comments do not invade any intellectual property right, including without limitation copyright, patent or trademark of any third party;
        The Comments do not contain any defamatory, libelous, offensive, indecent or otherwise unlawful material which is an invasion of privacy
        The Comments will not be used to solicit or promote business or custom or present commercial activities or unlawful activity.
        You hereby grant Company Name a non-exclusive license to use, reproduce, edit and authorize others to use, reproduce and edit any of your Comments in any and all forms, formats or media.
        
        Hyperlinking to our Content
        The following organizations may link to our Website without prior written approval:
        
        Government agencies;
        Search engines;
        News organizations;
        Online directory distributors may link to our Website in the same manner as they hyperlink to the Websites of other listed businesses; and
        System wide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our Web site.
        These organizations may link to our home page, to publications or to other Website information so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products and/or services; and (c) fits within the context of the linking party's site.
        
        We may consider and approve other link requests from the following types of organizations:
        
        commonly-known consumer and/or business information sources;
        dot.com community sites;
        associations or other groups representing charities;
        online directory distributors;
        internet portals;
        accounting, law and consulting firms; and
        educational institutions and trade associations.
        We will approve link requests from these organizations if we decide that: (a) the link would not make us look unfavorably to ourselves or to our accredited businesses; (b) the organization does not have any negative records with us; (c) the benefit to us from the visibility of the hyperlink compensates the absence of Company Name; and (d) the link is in the context of general resource information.
        
        These organizations may link to our home page so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products or services; and (c) fits within the context of the linking party's site.
        
        If you are one of the organizations listed in paragraph 2 above and are interested in linking to our website, you must inform us by sending an e-mail to Company Name. Please include your name, your organization name, contact information as well as the URL of your site, a list of any URLs from which you intend to link to our Website, and a list of the URLs on our site to which you would like to link. Wait 2-3 weeks for a response.
        
        Approved organizations may hyperlink to our Website as follows:
        
        By use of our corporate name; or
        By use of the uniform resource locator being linked to; or
        By use of any other description of our Website being linked to that makes sense within the context and format of content on the linking party's site.
        No use of Company Name's logo or other artwork will be allowed for linking absent a trademark license agreement.
        
        iFrames
        Without prior approval and written permission, you may not create frames around our Webpages that alter in any way the visual presentation or appearance of our Website.
        
        Content Liability
        We shall not be hold responsible for any content that appears on your Website. You agree to protect and defend us against all claims that is rising on your Website. No link(s) should appear on any Website that may be interpreted as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights.
        
        Reservation of Rights
        We reserve the right to request that you remove all links or any particular link to our Website. You approve to immediately remove all links to our Website upon request. We also reserve the right to amen these terms and conditions and it's linking policy at any time. By continuously linking to our Website, you agree to be bound to and follow these linking terms and conditions.
        
        Removal of links from our website
        If you find any link on our Website that is offensive for any reason, you are free to contact and inform us any moment. We will consider requests to remove links but we are not obligated to or so or to respond to you directly.
        
        We do not ensure that the information on this website is correct, we do not warrant its completeness or accuracy; nor do we promise to ensure that the website remains available or that the material on the website is kept up to date.
        
        Disclaimer
        To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions relating to our website and the use of this website. Nothing in this disclaimer will:
        
        limit or exclude our or your liability for death or personal injury;
        limit or exclude our or your liability for fraud or fraudulent misrepresentation;
        limit any of our or your liabilities in any way that is not permitted under applicable law; or
        exclude any of our or your liabilities that may not be excluded under applicable law.
        The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer, including liabilities arising in contract, in tort and for breach of statutory duty.
        
        As long as the website and the information and services on the website are provided free of charge, we will not be liable for any loss or damage of any nature.
         */ }
            </div></div>;

        return content;
    },
    render: function () {
        return (
            <MessageBox
                title="Uslovi korišćenja"
                conbtent={this.generateContent()}
                onClick={this.props.onClose}
            />
        );
    }
});

export default TermsAndConditions;