import '../../style/clients-page.css';
import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
var createReactClass = require('create-react-class');
var React = require('react');

var CustomDropdown = createReactClass({
    getInitialState: function () {
        return {
            button_value: this.props.selectedValue
        }
    },
    onChange: function (item) {
        this.setState({ button_value: item });
        this.props.onChange(item);
    },
    render: function () {
        return (
            <div class="form-group">
                <div class="static-clients-card-info-row">
                    <label for={this.props.dropdownId}>{this.props.labelValue}</label>
                </div>
                <button type="button" class="btn btn-secondary btn-sm dropdown-toggle"
                    id={this.props.dropdownId}
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {this.state.button_value}
                </button>
                <div class="dropdown-menu dropdown-order-state-menu"
                    aria-labelledby={this.props.dropdownId}>
                    {this.props.items !== null ?
                        (this.props.items.map(function (item, index) {
                            return (
                                <a class="dropdown-item " onClick={this.onChange.bind(this, item)} id={"" + index} >{item}</a>
                            );
                        }.bind(this)))
                        : null}
                </div>
            </div>
        );
    }
});

export default CustomDropdown;