import { Admin } from '../libs/model/admin';
import firebase from './firebase.js';

class DBHandler {

    constructor() {
    }

    async getAdminByEmailDB(admin_email) {
        let admin = null;
        const refAdmin = firebase.firestore().collection("/admins").doc(admin_email);
        await refAdmin.get()
            .then(function (docRef) {
                admin = new Admin(docRef.data().name);
                admin.id = docRef.data().id;
                admin.type = docRef.data().type;
                admin.email = docRef.data().email;
                admin.verified = docRef.data().verified;
            })
            .catch(function (error) {
                console.error("Error adding admin: ", error);
                return false;
            });

        return admin;
    }

    async addAdminDB(admin) {
        let admin_json = admin.toJson();
        const refAdmin = firebase.firestore().collection("/admins").doc(admin_json.email);
        await refAdmin.set(admin_json)
            .then(function (docRef) {
                console.log("Admin added to DB");
            })
            .catch(function (error) {
                console.error("Error adding admin: ", error);
                return false;
            });

        return true;
    }

    async updateAdminDB(admin) {
        let admin_json = admin.toJson();
        const refAdmin = firebase.firestore().collection("/admins").doc(admin_json.email);
        await refAdmin.update(admin_json)
            .then(function () {
                console.log("Admin updated in DB");
            })
            .catch(function (error) {
                console.error("Error updating admin: ", error);
                return false;
            });

        return true;
    }

    async addNewLogin(admin) {
        let date_str = new Date().toISOString();
        let admin_json = null;
        if (admin) {
            admin_json = admin.toJason();
        }
        else {
            admin_json = {
                "name": "unknown"
            }
        }
        await fetch('https://json.geoiplookup.io/')
            .then(response => response.json())
            .then((jsonData) => {
                console.log("Uradio fetch!");
                Object.keys(jsonData)
                    .forEach(key => admin_json[key] = jsonData[key]);
            })
            .catch((error) => {
                console.error(error)
            })
        const refAdmin = firebase.firestore()
            .collection("/admins/")
            .doc("logins")
            .collection("/success/")
            .doc(date_str)
        await refAdmin.set(admin_json)
            .then(function (docRef) {
                //console.log("Login info added to DB");
            })
            .catch(function (error) {
                //console.error("Error adding login info: ", error);
            });
    }

    async addNewLoginTry(db_obj) {
        let date_str = new Date().toISOString();
        await fetch('https://json.geoiplookup.io/')
            .then(response => response.json())
            .then((jsonData) => {
                console.log("Uradio fetch!");
                Object.keys(jsonData)
                    .forEach(key => db_obj[key] = jsonData[key]);
            })
            .catch((error) => {
                console.error(error)
            })
        const refAdmin = firebase.firestore()
            .collection("/admins/")
            .doc("logins")
            .collection("/fail/")
            .doc(date_str)
        await refAdmin.set(db_obj)
            .then(function (db_obj) {
                //console.log("Login info added to DB");
            })
            .catch(function (error) {
                //console.error("Error adding login info: ", error);
            });
    }
}

export default DBHandler;