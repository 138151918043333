import { storage } from './firebase.js';

class StorageHandler {

    constructor(onUploadChange, onUploadFinish) {
        this.onUploadChange = onUploadChange;
        this.onUploadFinish = onUploadFinish;
    }

    uploadImageToRef(client_id, file) {
        const upload_img_task = storage.ref('images/' + client_id).child(file.name).put(file);

        upload_img_task.on(
            "state_changed",
            snapshot => { 
                let percentage = Math.round((snapshot.bytesTransferred/snapshot.totalBytes) * 100);
                this.onUploadChange(percentage);
            },
            error => {
                console.log(error);
             },
            () => {
                storage
                    .ref('images/' + client_id)
                    .child(file.name)
                    .getDownloadURL()
                    .then(url => {
                        this.onUploadFinish(url);
                    });
            }
        );
    }

    deleteImageFromRef(ref, img_name) {

    }
}

export default StorageHandler