
class MenuSubitem {
    private _id: string;
    private _index: number;
    private _showOnWebsite: boolean;
    private _name: string;
    private _price: number;
    private _isRadioButton: boolean;
    private _group: string;
    private _selected: boolean;
    private _usePrice: boolean;
    private _exclusive: boolean;
    private _excludedGroups: [];
    private _maxItemsFromGroup: number;
    private _selectionPriceTrigger: number;
    private _useInName: boolean;

    constructor();
    constructor(index: number, showOnWebsite: boolean, name: string, price: number, isRadioButton: boolean);
    constructor(index?: number, showOnWebsite?: boolean, name?: string, price?: number, isRadioButton?: boolean) {
        this._index = index !== undefined ? index : 0;
        this._showOnWebsite = showOnWebsite !== undefined ? showOnWebsite : false;
        this._name = name !== undefined ? name : "";
        this._price = price !== undefined ? price : 0;
        this._isRadioButton = isRadioButton !== undefined ? isRadioButton : false;
        this._id = "";
        this._group = "";
        this._selected = false;
        this._usePrice = false;
        this._exclusive = false;
        this._excludedGroups = [];
        this._maxItemsFromGroup = 0;
        this._selectionPriceTrigger = 0;
        this._useInName = false;
    }

    get id(): string {
        return this._id;
    }
    set id(value: string) {
        this._id = value;
    }

    get index(): number {
        return this._index;
    }
    set index(value: number) {
        this._index = value;
    }

    get showOnWebsite(): boolean {
        return this._showOnWebsite;
    }
    set showOnWebsite(value: boolean) {
        this._showOnWebsite = value;
    }

    get name(): string {
        return this._name;
    }
    set name(value: string) {
        this._name = value;
    }

    get price(): number {
        return this._price;
    }
    set price(value: number) {
        this._price = value;
    }

    get isRadioButton(): boolean {
        return this._isRadioButton;
    }
    set isRadioButton(value: boolean) {
        this._isRadioButton = value;
    }

    get group(): string {
        return this._group;
    }
    set group(value: string) {
        this._group = value;
    }

    get selected(): boolean {
        return this._selected;
    }
    set selected(value: boolean) {
        this._selected = value;
    }

    get usePrice(): boolean {
        return this._usePrice;
    }
    set usePrice(value: boolean) {
        this._usePrice = value;
    }

    get exclusive(): boolean {
        return this._exclusive;
    }
    set exclusive(value: boolean) {
        this._exclusive = value;
    }

    get excludedGroups(): [] {
        return this._excludedGroups;
    }
    set excludedGroups(value: []) {
        this._excludedGroups = value;
    }

    get maxItemsFromGroup(): number {
        return this._maxItemsFromGroup;
    }
    set maxItemsFromGroup(value: number) {
        this._maxItemsFromGroup = value;
    }

    get selectionPriceTrigger(): number {
        return this._selectionPriceTrigger;
    }
    set selectionPriceTrigger(value: number) {
        this._selectionPriceTrigger = value;
    }

    get useInName(): boolean {
        return this._useInName;
    }
    set useInName(value: boolean) {
        this._useInName = value;
    }

    toJson() {
        return {
            id: this._id,
            index: this._index,
            showOnWebsite: this._showOnWebsite,
            name: this._name,
            price: this._price,
            isRadioButton: this._isRadioButton,
            group: this._group,
            selected: this.selected,
            usePrice: this.usePrice,
            exclusive: this._exclusive,
            excludedGroups: this._excludedGroups,
            maxItemsFromGroup: this._maxItemsFromGroup,
            selectionPriceTrigger: this._selectionPriceTrigger,
            useInName: this._useInName,
        }
    }

    loadFromJson(jsonObject: any) {
        if (jsonObject) {
            if (jsonObject.id) {
                this.id = jsonObject.id;
            }
            if (jsonObject.index) {
                this.index = jsonObject.index;
            }
            if (jsonObject.showOnWebsite) {
                this._showOnWebsite = jsonObject.showOnWebsite;
            }
            if (jsonObject.name) {
                this._name = jsonObject.name;
            }
            if (jsonObject.price) {
                this._price = jsonObject.price;
            }
            if (jsonObject.isRadioButton) {
                this._isRadioButton = jsonObject.isRadioButton;
            }
            if (jsonObject.group) {
                this._group = jsonObject.group;
            }
            if (jsonObject.selected) {
                this._selected = jsonObject.selected;
            }
            if (jsonObject.usePrice) {
                this._usePrice = jsonObject.usePrice;
            }
            if (jsonObject.exclusive) {
                this._exclusive = jsonObject.exclusive;
            }
            if (jsonObject.excludedGroups) {
                this._excludedGroups = jsonObject.excludedGroups;
            }
            if (jsonObject.maxItemsFromGroup) {
                this._maxItemsFromGroup = jsonObject.maxItemsFromGroup;
            }
            if (jsonObject.selectionPriceTrigger) {
                this._selectionPriceTrigger = jsonObject.selectionPriceTrigger;
            }
            if (jsonObject.useInName) {
                this._useInName = jsonObject.useInName;
            }
        }
    }
}

export default MenuSubitem