import '../../style/clients-page.css';
import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import StaticClientModule from './static-clients-module.js'
var createReactClass = require('create-react-class');
var React = require('react');


var buttonData = [
    { index: 0, name: 'Statički klijenti', isEnabled: true, selected_menu: "static" },
    { index: 1, name: 'Dinamički klijenti', isEnabled: false, selected_menu: "dynamic" },
    { index: 3, name: 'Opcije', isEnabled: false, selected_menu: "options" },
];

var ClientsModule = createReactClass({
    getInitialState: function () {
        return {
            selected_menu: buttonData[0].selected_menu
        }
    },
    onMenuButtonClick: function (selected_menu) {
        this.setState({
            selected_menu: selected_menu,
        })
    },
    render: function () {
        return (
            <div className="clients-preview">
                <div className="clients-module">
                    {this.state.selected_menu == "static" ? <StaticClientModule /> : null}
                    {this.state.selected_menu == "dynamic" ? null : null}
                    {this.state.selected_menu == "options" ? null : null}
                </div>
            </div>
        );
    }
});


var ClientsPage = createReactClass({
    render: function () {
        return (
            <div>
                <ClientsModule />
            </div>
        );
    }
});


export default ClientsPage;