import '../../style/clients-page.css';
import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
var createReactClass = require('create-react-class');
var React = require('react');

var ButtonAddLocation = createReactClass({
    render: function () {
        return (
            <div class="static-cl-card-button-address-div">
                <button onClick={this.props.onClick.bind(this, this.props.index1, this.props.index2)} type="button"
                    className={"btn btn-secondary add-address"}>
                    <h4>+</h4>
                </button>
            </div>
        );
    }
});

export default ButtonAddLocation;