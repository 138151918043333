import './style/App.css';
import ClientsPage from './libs/clients_page/clients-page';
import DbPatcher from './libs/dbPatcher/dbPatcher';
var React = require('react');
var createReactClass = require('create-react-class');

var tabData = [
  { index: 0, name: 'Dostava', isActive: true, isEnabled: true },
  { index: 1, name: 'Prodavnice', isActive: false, isEnabled: true },
  { index: 2, name: 'Vozila i osoblje', isActive: false, isEnabled: false },
  { index: 3, name: 'Korisnici', isActive: false, isEnabled: false },
  { index: 4, name: 'Podrška', isActive: false, isEnabled: true },
  { index: 5, name: 'DB Patcher', isActive: false, isEnabled: true }
];

var Tabs = createReactClass({
  render: function () {
    return (
      <ul className="nav nav-pills">
        {tabData.map(function (tab) {
          return (
            <Tab data={tab} isActive={this.props.activeTab === tab}
              handleClick={this.props.changeTab.bind(this, tab)}
              isEnabled={tab.isEnabled} />
          );
        }.bind(this))}
      </ul>
    );
  }
});
var Tab = createReactClass({
  render: function () {
    return (
      <li onClick={this.props.isEnabled ? this.props.handleClick : null} className="nav-item">
        <a href="#" className={"nav-link" +
          (this.props.isActive ? " active" : "") +
          (this.props.isEnabled ? "" : " disabled")}>{this.props.data.name}</a>
      </li>
    );
  }
});
var Content = createReactClass({
  render: function () {
    return (
      <div>
        {this.props.activeTab.index === 0 ?
          <>
            <h1>Za pracenje dostava koristi novi portal. Ovde vise ne radi.</h1>
            <h4>Prodavnice, na zalost, jedino ovako mozemo menjati.</h4>
            <p><h5>Par saveta za koriscenje kartice "Prodavnice": </h5> <br />
              1. Ako nesto kliknes i ocekujes promenu elementa, a nista se ne desava. Refresh-uj stranicu. <br />
              Ponovno kliktanje ce mozda opet izmeniti element na nacin koji ne zelis. <br />
              2. Izbegavati brisanje elemenata u sredini. Postoji bug sa generisanjem index-a, pa ce se posle pobrljaviti sa dodavanjem. <br />
              Ukoliko je potrebno obrisati element, spustiti ga dole i onda tek obrisati. Tada ce obrisati poslednji index. <br />
              3. Indeksiranje lokala je ugaseno. Ne mogu da se pomeraju gore-dole.<br />

            </p>
            <br />
            <p><h5>Tipovi lokala:</h5> <br />
              <strong>drink </strong> <br />
              <strong>fast_food </strong><br />
              <strong>restaurants </strong><br />
              <strong>shops</strong> <br />
              <br />
              * id-jeve kopirati ovakve kakvi jesu u polje za setovanje tipa lokala. Ukoliko je potrebno definisati vise, odvojiti zarezom bez razmaka.
            </p>
            <br />
            <p><h5>Struktura podataka:</h5> <br />
              <strong>Client </strong> - Glavni objekat koji sadrzi osnovne informacije o lokalu (slike, adrese, telefone....), podatak o aktiviranoj promociji (sada svima treba aktivirati), kao i kolekciju menija <br />
              <strong>Menu</strong> - Vise menija moze biti sadrzano u jednom klijentu. <br />
              Svaki meni sadrzi informacije o slici, koja se prikazuje u aside-menu-ju na klijentskoj strani (ne radi trenutno edit) i ime. <br />
              <strong>MenuItem</strong> - Element koji je sadrzan u samom Menu objektu. Predstavlja jedan artikal prodavnice ili jednu porciju menija restorana. <br />
              Cena za njega se definise ukoliko nema razlike u ceni u zavisnosti od nekog kriterijuma (npr gramaza). Ako ima, onda se cena ostavi prazna i definise posle u child objektu (Subitem-u). <br />
              <strong>SubItem</strong> - Objekat nije obavezan da se definise unutar Item-a. <br />
              Potreban je kada neki Item ima dodatne sastojke koji su opcionalni ili ako Item ima razlicite cene po gramazi. <br />
              Ukoliko se radi o npr premazima, potrebno je definisati istu grupu za sve premaze i namestiti da <strong>nisu</strong> radio button. <br />
              U slucaju da je SubItem potreban da se definise gramaza proizvoda, onda je opet potrebna identicna grupa, ali on tada treba da bude radio button.  <br />
              SubItem treba da bude radio button samo kada jedna od opcija treba da bude selektovana iz konkretne grupe.  <br />
              Moze se definisati vise razlicitih grupa sa razlicitim SubItem-ima.

            </p>
            <p><h5>Koriscenje propertija 'Koristi cenu':</h5> <br />
              Porpeti se koristi u situacijama kada je potrebno prikazati cenu na sajtu i uracunati je u ukupnu cenu proizvoda. <br />
              Potrebno je da bude cekiran na MenuItem-u, ukoliko on nema nekih SubItem-a. Tada ce koristiti samo njegovu cenu jer nema druge. <br />
              U situacijama kada Menu Item ima npr. razlicite velicine, pa je SubItem-om potrebno definisati cenu, onda se na MeniItem-u ovaj property <strong>ne cekira</strong>.
              <br /> Zatim, potrebno je definisati set od par SubItem-a sa razlicitim cenama i imenima (Mali, srednji, veliki) i svaki <strong>mora biti cekiran</strong> sa 'Koristi cenu'.
              <br /> Ovi subitem-i treba da budu tipa Radio Button. I najmanja cena mora biti na vrhu jer ce biti koriscena za prikaz 'Cena: od.... '<br />
              <br />Postoje situacije kada subitem-om definisemo samo dodatke za MenuItem (premazi, dodatno pice, itd...). Tada za svaki SubItem mora biti cekirano da se koristi cena. <br />
              U tim situacijama je SubItem najcesce nije Radio Button, vec Check box. <br />
              Koriscenje cene za sam Item je opcionalno u ovom slucaju. Ako ima velicinu, potrebno je uraditi definisanje radio buttona, kako je opisano, u suprotnom mogu se definisati samo ovi dodaci i koristiti cena samog Itema.
              <br /> Neki dodaci, naravno, nece imati cenu jer se mozda ne naplacuju, pa se ista onda nece definisati za njih i nece se ni koristiti property 'Koristi cenu'.
              <br /><br /> Postoji poseban slucaj kada se definise 'broj dzaba subitem-a' (kako na srpskom napisati 'selectionPriceTrigger').
              <br />To je broj koji govori koliko subitema moze biti selektovano u jednoj grupi za dz, a svi ostali ce se naplatiti.
              <br /> U tom slucaju se ovaj properti ne sme selektovati jer se po default-u sub item ne naplacuje. Sama aplikacija odredjuje kada ce aktivirati naplatu.
              <br />
            </p>
            <p><h5>Koriscenje propertija 'Iskljucene grupe':</h5> <br />
              Ovaj properti se koristi u SubItem-u i definise jednu ili vise drugih grupa subItem-a (odvojene samo zarezom, bez razmaka!), koje ce biti ugasene uoliko se selektuje <br />
              subitem u kom je ovo definisano.
              <br />Koristi se u situacijama kada npr. neki item ima definisani set premaza u jednom subItem-u koji bi automacki trebao da zabrani selektovanje pojedinacnih premaza.
              <br />
            </p>
            <p><h5>Koriscenje propertija 'Maksimalan broj selektovanih subitema iz grupe':</h5> <br />
              Sam naziv govori sve. Dovoljno je definisati u jednom subitem-u u grupi i cela grupa ce biti ogranicena. Ogranicenje se gasi sa 0.
            </p>
            <p><h5>Koriscenje propertija 'Broj dzaba subitem-a':</h5> <br />
              opisano je ranije u poglavlju u kom je opisano koriscenje propertija 'Koristi cenu'.
              <br /> Dovoljno je jedan da bude definisan u grupi i trigerovace naplatu subitem-a kada broj selektovanih u grupi predje definisani broj.
              <br /> Opcija se gasi sa 0 (nulom).
              <br /> Vazno je da se za svaki subitem definise cena, ali da se ne selektuje "koristi cenu".
              <br /> Ovaj broj predstavlja granicnu vrednost i treba staviti za jedan veci nego sto predstavlja.
            </p>

            <br /><br /><br />
            <h1>Nek je sa srecom!</h1>
          </> : null}
        {this.props.activeTab.index === 1 ? <ClientsPage /> : null}
        {this.props.activeTab.index === 4 ? <h1>Imas podrsku i tapsanje po ramenu. Samo napred!</h1> : null}
        {this.props.activeTab.index === 5 ? <DbPatcher /> : null}
      </div>
    );
  }
});

var LanguageButton = createReactClass({
  render: function () {
    return (
      <div class="dropdown-language">
        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          img
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a class="dropdown-item" href="#"><img src="./img/lang_srb_img.png" alt="img_serb_lang" /></a>
        </div>
      </div>
    );
  }
});

var Hr = createReactClass({
  render: function () {
    return (
      <hr className="red-border" />
    );
  }
});
var App = createReactClass({
  getInitialState: function () {
    return {
      activeTab: tabData[0]
    }
  },
  handleClick: function (tab) {
    this.setState({ activeTab: tab });
  },
  render: function () {
    return (
      <div>
        <div>
          <Tabs activeTab={this.state.activeTab} changeTab={this.handleClick} />
          <button class="btn btn-outline-danger"
            onClick={() => this.props.logOut()}>
            Izloguj se
          </button>
          <LanguageButton />

        </div>
        <Hr />
        <Content activeTab={this.state.activeTab} />
      </div>
    );
  }
});

export default App;
