import ClientType from "../libs/model/clientType";
import Address from "../libs/model/address";
import WorkingTime from "../libs/model/workingTime";
import firebase from './firebase.js';
import Clients from "../libs/model/clients";
import Client from "../libs/model/client";
import Menu from "../libs/model/menu";
import MenuItem from "../libs/model/menuItem";
import MenuSubitem from "../libs/model/menuSubItem";
import User from "../libs/model/user";
import Order from "../libs/model/order";

var clients = new Clients();

var clients_by_id = {};
var menus_by_client = {};
var menu_items_by_menu = {};
var menu_subitems_by_menu_item = {};
var reload_methods_collection = [];


class DBHandler {

    constructor(onCollectionReload) {
        this.onCollectionReload = onCollectionReload;
    }

    addReloadMethod(method_to_add) {
        reload_methods_collection.push(method_to_add);
    }

    invokeReload() {
        reload_methods_collection.forEach(function (method_to_invoke) {
            method_to_invoke();
        });
    }

    clientsObj() {
        return clients;
    }

    clientsFromDB() {
        return clients_by_id;
    }

    menusByClient(client_id) {
        return menus_by_client[client_id];
    }

    menuItemsByMenu(menu_id) {
        return menu_items_by_menu[menu_id];
    }

    menuSubitemByMenuItem(menu_item_id) {
        return menu_subitems_by_menu_item[menu_item_id];
    }



    //#region Clients

    addNewClient() {
        let _client = clients.addNewClient();
        _client.name = "Lokal u najavi"
        this.addOrUpdateClientDB(_client);
    }

    moveClientUp(client_index) {
        // if (client_index === 0) {
        //     return;
        // }
        // clients.moveUp(client_index);
        // this.addOrUpdateClientDB(clients.children[client_index])
        //     .then(this.addOrUpdateClientDB(clients.children[client_index - 1]));
    }

    moveClientDown(client_index) {
        // if ((Object.keys(clients.children).length - 1) === client_index) {
        //     return;
        // }
        // clients.moveDown(client_index);
        // this.addOrUpdateClientDB(clients.children[client_index])
        //     .then(this.addOrUpdateClientDB(clients.children[client_index + 1]));
    }

    async getClientsFromDB(onDbFinish) {
        let refClients = firebase.firestore().collection("/clients/");
        await refClients.get()
            .then(function (querySnapshotClients) {
                clients = new Clients();
                querySnapshotClients.forEach(function (client_from_db) {
                    if (client_from_db) {
                        let client = new Client();
                        client.loadFromJson(client_from_db.data());
                        client.loadFromJson(client_from_db);
                        if (client && client.id) {
                            clients_by_id[client.id] = client;
                            clients.addClient(client);
                        }
                    }
                    else {
                        console.log("Client from DB is null!");
                    }
                });
            })
            .catch(function (error) {
                console.error("Error while loading clients: ", error);
            });

        if (onDbFinish) {
            onDbFinish(clients);
        }
        this.invokeReload();
        return clients;
    }

    async getClientByIdDB(client_id) {
        let client = null;
        let refClient = firebase.firestore().collection("/clients").doc(client_id);
        await refClient.get()
            .then(function (clientRef) {
                let client = new Client();
                client.loadFromJson(clientRef.data());
                client.loadFromJson(clientRef);
            })
            .catch(function (error) {
                console.log("Error getting Client from DB: ", error);
            });
        if (client && client.id) {
            clients_by_id[client.id] = client;
        }
        this.invokeReload();
    }

    async addOrUpdateClientDB(client) {
        let client_json = client.toJason();
        if (client_json.id) {//update
            const refClient = firebase.firestore().collection("/clients").doc(client_json.id);
            await refClient.update(client_json)
                .then(function () {

                    console.log("Client successfully updated in DB!");
                })
                .catch(function (error) {
                    // The document probably doesn't exist.
                    console.error("Error updating client: ", error);
                });
        }
        else {//add new
            const refClients = firebase.firestore().collection("/clients");
            await refClients.add(client_json)
                .then(function (docRef) {
                    client.id = docRef.id;
                    console.log("Client added to DB with ID: ", docRef.id);
                })
                .catch(function (error) {
                    console.error("Error adding Client: ", error);
                });
        }
        if (client && client.id) {
            clients_by_id[client.id] = client;
        }
        this.invokeReload();
    }

    async removeClientFromDB(client) { // TODO: Update all clients with bigger index to remove the gap
        if (client.id) {
            let refClient = firebase.firestore().collection("/clients").doc(client.id);
            await refClient.delete()
                .then(function () {
                    console.log("Client is deleted from DB!");
                    clients.removeClient(client);
                })
                .catch(function (error) {
                    console.log("Error deleting Client from DB: ", error);
                });
            this.invokeReload();
        }
        else {
            console.log("Client does not have defined the ID!")
        }
    }

    async getClientTypesDB(onDbFinish) {
        let clientTypes = [];
        const getClientTypesFunc = firebase.functions().httpsCallable('getClientTypes');
        getClientTypesFunc()
            .then((result) => {
                if (result.data) {
                    Object.values(result.data).map((clientTypeJSON) => {
                        let clientTypeObj = new ClientType();
                        clientTypeObj.loadFromJson(clientTypeJSON);
                        clientTypes.push(clientTypeObj);
                    })
                }
            })
            .catch((result) => {
                console.log(result);
            })
            .finally(() => {
                onDbFinish(clientTypes);
            })
    }

    //#endregion Clients

    //#region Menus
    moveMenuUp(client, menu_index) {
        if (menu_index === 0) {
            return;
        }
        client.moveUp(menu_index);
        if (client.menus[menu_index]) {
            this.addOrUpdateMenuDB(client.id, client.menus[menu_index]);
        }
        if (client.menus[menu_index - 1]) {
            this.addOrUpdateMenuDB(client.id, client.menus[menu_index - 1]);
        }
    }

    moveMenuDown(client, menu_index) {
        if ((Object.keys(client.menus).length - 1) === menu_index) {
            return;
        }
        client.moveDown(menu_index);
        if (client.menus[menu_index]) {
            this.addOrUpdateMenuDB(client.id, client.menus[menu_index]);
        }
        if (client.menus[menu_index + 1]) {
            this.addOrUpdateMenuDB(client.id, client.menus[menu_index + 1]);
        }
    }

    async getMenusFromClientDB(client, onDbFinish) {
        let client_id = client.id;
        let refMenus = firebase.firestore()
            .collection("/clients/")
            .doc(client_id)
            .collection("/menus/");
        try {
            const querySnapshotMenus = await refMenus.get();
            client.menus = {};
            querySnapshotMenus.forEach(async (menu_from_db) => {
                let menu = new Menu();
                menu.loadFromJson(menu_from_db);
                menu.loadFromJson(menu_from_db.data());
                if (menu && menu.id) {
                    client.addMenu(menu);
                }
            });
        }
        catch (ex) {
            console.error("Error while loading clients: ", ex);
        }
        if (onDbFinish) {
            onDbFinish();
        }
        this.invokeReload();
    }

    async getMenuByIdFromClientDB(client_id, menu_id) {
        let menu = null;
        let refMenu = firebase.firestore()
            .collection("/clients/")
            .doc(client_id)
            .collection("/menus/")
            .doc(menu_id);
        await refMenu.get()
            .then(function (menuRef) {
                let menu = new Menu();
                menu.loadFromJson(menuRef);
                menu.loadFromJson(menuRef.data());
            })
            .catch(function (error) {
                console.log("Error getting Menu from DB: ", error);
            });
        if (menu && menu.id) {
            if (!menus_by_client[client_id]) {
                menus_by_client[client_id] = [];
            }
            menus_by_client[client_id].push(menu);
        }
        this.invokeReload();
    }

    async addOrUpdateMenuDB(client_id, menu) {
        let menu_json = menu.toJson();

        if (menu_json.id) {//update
            const refMenu = firebase.firestore()
                .collection("/clients/")
                .doc(client_id)
                .collection("/menus/")
                .doc(menu_json.id);
            await refMenu.set(menu_json)
                .then(function () {
                    console.log("Menu successfully updated in DB!");
                })
                .catch(function (error) {
                    // The document probably doesn't exist.
                    console.error("Error updating menu: ", error);
                });
        }
        else {//add new
            const refMenu = firebase.firestore()
                .collection("/clients/")
                .doc(client_id)
                .collection("/menus/");
            await refMenu.add(menu_json)
                .then(function (docRef) {
                    menu.id = docRef.id;
                    console.log("Menu added to DB with ID: ", docRef.id);
                })
                .catch(function (error) {
                    console.error("Error adding menu: ", error);
                });
        }
        if (menu && menu.id) {
            if (!menus_by_client[client_id]) {
                menus_by_client[client_id] = [];
            }
            menus_by_client[client_id].push(menu);
        }
        this.invokeReload();
    }

    async removeMenuFromDB(client, menu) {// TODO: Update all menus with bigger index to remove the gap
        if (client.id && menu.id) {
            let refClient = firebase.firestore()
                .collection("/clients")
                .doc(client.id)
                .collection("/menus/")
                .doc(menu.id);
            await refClient.delete()
                .then(function () {
                    console.log("Menu is deleted from DB!");
                })
                .catch(function (error) {
                    console.log("Error deleting Menu from DB: ", error);
                });
            this.invokeReload();
        }
        else {
            console.log("Client and Menu does not have defined the ID!")
        }
    }

    //#endregion Menus

    async getAddressesByIdsDB(ids, onDbFinish) {
        const getAddressesByIdsFunc = firebase.functions().httpsCallable('getAddressesByIds');
        try {
            const result = await getAddressesByIdsFunc({ addressesIds: ids });
            const addressesObj = [];
            if (result.error) {
                console.log(result);
            }
            else if (result.data && result.data.addresses && Object.values(result.data.addresses).length > 0) {
                Object.values(result.data.addresses).forEach(addressJson => {
                    let tempAddressObj = new Address();
                    tempAddressObj.loadFromJson(addressJson);
                    addressesObj.push(tempAddressObj);
                });
            }
            else {
                console.log("There is no Data in order DB respond!");
            }

            if (onDbFinish) {
                onDbFinish(addressesObj);
            }
            return addressesObj;
        }
        catch (ex) {
            console.log(ex);
            if (onDbFinish) {
                onDbFinish(ex);
            }
            return [];
        }
    }

    async updateUserDB(user, onDbFinish) {
        const updateUserFunc = firebase.functions().httpsCallable('updateUser');
        updateUserFunc({ user: user.toJason() })
            .then((result) => {
                if (result.error) {
                    console.log(result);
                }
                if (onDbFinish) {
                    onDbFinish();
                }
            })
            .catch((result) => {
                console.log(result);
            });
    }

    async getUserByIdDB(userId, onDbFinish) {
        const getUserByIDFunc = firebase.functions().httpsCallable('getUserByID');
        try {
            const result = await getUserByIDFunc({ userId: userId });
            if (result.data && result.data.user) {
                const userObj = new User();
                userObj.loadFromJson(result.data.user);
                if (onDbFinish) {
                    onDbFinish(userObj);
                }
                return userObj;
            }
            else {
                if (onDbFinish) {
                    onDbFinish(null);
                }
            }
            return null;
        }
        catch (ex) {
            console.log(ex);
            if (onDbFinish) {
                onDbFinish(ex);
            }
            return null;
        }
    }

    async getAllUsers(onDbFinish) {
        const getAllUsers = firebase.functions().httpsCallable('getUsers');
        try {
            const result = await getAllUsers({});
            if (result.data && result.data.users) {
                const retObj = [];
                result.data.users.forEach(userDB => {
                    const userObj = new User();
                    userObj.loadFromJson(userDB);
                    if (userObj.phone) {
                        retObj.push(userObj);
                    }
                });
                if (onDbFinish) {
                    onDbFinish(retObj);
                }
                return retObj;
            }
            else {
                if (onDbFinish) {
                    onDbFinish(null);
                }
            }
            return [];
        }
        catch (ex) {
            console.log(ex);
            if (onDbFinish) {
                onDbFinish(ex);
            }
            return [];
        }
    }

    async updateOrderDB(order, updateOrderState, cancelOrder, onDbFinish) {
        const addOrderFunc = firebase.functions().httpsCallable('updateOrderToDB');
        try {
            const result = await addOrderFunc({
                order: order.toJason(),
                changeState: updateOrderState,
                cancel: cancelOrder,
            });
            if (result.error) {
                console.log(result);
            }
            let orderObj = null;
            if (result.data.order) {
                orderObj = new Order();
                orderObj.loadFromJson(result.data.order);
            }
            if (onDbFinish) {
                onDbFinish(orderObj);
            }
            return orderObj;
        }
        catch (ex) {
            console.log(ex);
            if (onDbFinish) {
                onDbFinish(ex);
            }
            return null
        }
    }

    async getOrdersByStatesDB(orderStates, selectedDate, onDbFinish) {
        if (!orderStates) {
            console.log("States collection must have at least one element!");
            return [];
        }
        const dateInterval = selectedDate ? this.getDayIntervalFromDate(selectedDate) : null;
        const getOrdersByStatesFunc = firebase.functions().httpsCallable('getOrdersByStates');
        try {
            const result = selectedDate ? await getOrdersByStatesFunc({
                orderStates: orderStates,
                startDate: dateInterval.startDate,
                endDate: dateInterval.endDate
            }) :
                await getOrdersByStatesFunc({
                    orderStates: orderStates,
                    startDate: null,
                    endDate: null
                });
            const ordersObj = [];
            if (result.error) {
                console.log(result);
            }
            else if (result.data && result.data.orders && result.data.orders.length > 0) {
                result.data.orders.forEach(orderJson => {
                    let tempOrderObj = new Order();
                    tempOrderObj.loadFromJson(orderJson);
                    ordersObj.push(tempOrderObj);
                });
            }
            else {
                console.log("There is no Data in order DB respond!");
            }

            if (onDbFinish) {
                onDbFinish(ordersObj);
            }
            return ordersObj;
        }
        catch (ex) {
            console.log(ex);
            if (onDbFinish) {
                onDbFinish([]);
            }
            return [];
        }
    }

    async getOrdersByIdsDB(orderIds, sequence, useSequence, onDbFinish) {
        const getOrderByIdsFunc = firebase.functions().httpsCallable('getOrdersByIds');
        try {
            const result = await getOrderByIdsFunc({ orderIds: orderIds, sequence: sequence, useSequence: useSequence });
            const ordersObj = [];
            if (result.error) {
                console.log(result);
            }
            else if (result.data && result.data.orders && result.data.orders.length > 0) {
                result.data.orders.forEach(orderJson => {
                    let tempOrderObj = new Order();
                    tempOrderObj.loadFromJson(orderJson);
                    ordersObj.push(tempOrderObj);
                });
            }
            else {
                console.log("There is no Data in order DB respond!");
            }

            if (onDbFinish) {
                onDbFinish(ordersObj);
            }
            return ordersObj;
        }
        catch (ex) {
            console.log(ex);
            if (onDbFinish) {
                onDbFinish([]);
            }
        }
    }
    //#region help methods

    getDayIntervalFromDate(dateTime) {
        // var startDate = new Date(dateTime);
        // var endDate = new Date(dateTime);
        // startDate.setDate(startDate.getDate() - 1);
        // startDate.setHours(23);
        // startDate.setMinutes(59);
        // startDate.setSeconds(59);
        // endDate.setDate(endDate.getDate() + 1);
        // endDate.setHours(0);
        // endDate.setMinutes(0);
        // endDate.setSeconds(0);
        // return { startDate: startDate.getTime(), endDate: endDate.getTime() }
        return { startDate: null, endDate: null }
    }

    groupCustomIdsByTen(ids_hash) {
        let customIdsByTen = [];
        let index = 0;
        Object.keys(ids_hash)
            .forEach(function (custom_id, counter) {
                if (counter === 0 || counter % 10 === 0) {
                    if (counter !== 0) { index++ }
                    customIdsByTen[index] = [];
                }
                customIdsByTen[index].push(custom_id);
            });
        return customIdsByTen;
    }

    generateRandomClients(number_of_elements) {
        let clients = new Clients();
        for (let index = 0; index < number_of_elements; index++) {
            let workingTime = new WorkingTime(6, 0, 18, 30);
            let client = new Client(index,
                true,
                ("" + index + "_" + "ClietnName"),
                "client_type",
                "photo_link",
                workingTime);
            let menus = this.generateRandomMenus(number_of_elements);
            for (let counter = 0; counter < menus.length; counter++) {
                client.addMenu(menus[counter]);
            }
            let locations = this.generateRandomLocations(number_of_elements);
            for (let counter = 0; counter < locations.length; counter++) {
                client.addLocation(locations[counter]);
            }
            clients.addClient(client);
        }
        return clients;
    }

    generateRandomLocations(number_of_elements) {
        let locations = [];
        for (let index = 0; index < number_of_elements; index++) {
            let location = new Location("" + index + "_Location")
            location.addPhone("firstPhone");
            location.addPhone("secondPhone");
            location.addPhone("thirdPhone");
            locations.push(location);
        }
        return locations;
    }

    generateRandomMenus(number_of_elements) {
        let menus = [];
        for (let index = 0; index < number_of_elements; index++) {
            let menu = new Menu(index, true, ("" + index + "_Menu_name"), "icon");
            let menuItems = this.generateRandomMenuItems(number_of_elements);
            for (let counter = 0; counter < menuItems.length; counter++) {
                menu.addItem(menuItems[counter]);
            }
            menus.push(menu);
        }
        return menus;
    }

    generateRandomMenuItems(number_of_elements) {
        let menuItems = [];
        for (let index = 0; index < number_of_elements; index++) {
            let menuItem = new MenuItem(
                index,
                true,
                "Menu_item_name",
                "This is the description",
                "phot_link",
                (index % 2 == 0 ? 0 : 10));

            if (index % 2 == 0) {
                let subitems = this.generateRandomSubMenuItems(number_of_elements);
                for (let counter = 0; counter < subitems.length; counter++) {
                    menuItem.addItem(subitems[counter]);
                }
            }
            menuItems.push(menuItem)
        }
        return menuItems;
    }

    generateRandomSubMenuItems(number_of_elements) {
        let menuSubItems = [];
        for (let index = 0; index < number_of_elements; index++) {
            let menuSubItem = new MenuSubitem(index, true, "Subitem_name", 20, (index % 2 == 0));
            menuSubItems.push(menuSubItem);
        }
        return menuSubItems;
    }

    //endregion help methods
}

export default DBHandler