import { Admin } from '../libs/model/admin';
import DBHandler from './database-handler-admin.js';
import firebase from './firebase.js';

var authStateChangeFunction = null;

class AuthenticationManager {

    constructor(onLoginFunction, onLogoutFunction, onAuthStateChanged) {
        this.onLoginFunction = onLoginFunction;
        this.onLogoutFunction = onLogoutFunction;
        this.dbHandler = new DBHandler();
        this.onAuthStateChangedParent = onAuthStateChanged;
        firebase.auth().onAuthStateChanged(this.onAuthStateChanged);
    }

    addAuthStateChangeFunction(func) {
        authStateChangeFunction = func;
    }

    async singIn(email, password) {
        let db_data = null;
        await firebase.auth().createUserWithEmailAndPassword(email, password)
            .then((userCredential) => {
                db_data = userCredential.user;
                var user = firebase.auth().currentUser;
                user.sendEmailVerification();
            })
            .catch((error) => {
                //var errorCode = error.code;
                //var errorMessage = error.message;
                db_data = error;
            });
        if (db_data.code && db_data.message) {
            // Error occurred. TODO: Do something.
        }
        else {
            let admin_obj = new Admin(email.split('@')[0]);
            admin_obj.id = db_data.uid;
            admin_obj.email = email;
            db_data = admin_obj;
            this.dbHandler.addAdminDB(admin_obj);
            this.dbHandler.addNewLogin(admin_obj);
        }
        //TODO: if(db_data is user)
        if (this.onLoginFunction) {
            this.onLoginFunction(db_data);
        }
    }

    async logIn(email, password) {
        let db_data = null;
        await firebase.auth().signInWithEmailAndPassword(email, password)
            .then((userCredential) => {
                db_data = userCredential.user; // user
            })
            .catch((error) => {
                //var errorCode = error.code;
                //var errorMessage = error.message;
                db_data = error;
            });

        if (db_data.code && db_data.message) {
            // Error occurred. TODO: report message!
        }
        else {
            let admin_obj = await this.dbHandler.getAdminByEmailDB(email);
            this.dbHandler.addNewLogin(admin_obj);
            db_data = admin_obj;
        }
        //TODO: if(db_data is user)
        if (this.onLoginFunction) {
            this.onLoginFunction(db_data);
        }
    }

    isUserVerified(user_obj) {
        let user = firebase.auth().currentUser;
        if (user_obj) {
            if (user_obj.verified !== user.emailVerified) {
                user_obj.verified = user.emailVerified;
                this.dbHandler.updateAdminDB(user_obj);
            }
        }
        return user.emailVerified;
    }

    getCurrentUser() {
        return firebase.auth().currentUser;
    }

    isLoggedIn() {
        if (firebase.auth().currentUser) {
            return true;
        }
        else {
            return false;
        }
    }

    async onAuthStateChanged(user_db) {
        if (user_db && user_db.email) {
            let _dbHandler = new DBHandler();
            let admin_obj = await _dbHandler.getAdminByEmailDB(user_db.email);
            authStateChangeFunction(admin_obj);
        }
        else {
            authStateChangeFunction(null);
        }
    }

    async logOut() {
        firebase.auth().signOut()
            .then((userCredential) => {
                this.onLogoutFunction(false);
            })
            .catch((error) => {
                //var errorCode = error.code;
                //var errorMessage = error.message;
                //this.onLogoutFunction(error);
            });
    }

    async resendEmail() {
        var user = firebase.auth().currentUser;
        user.sendEmailVerification()
    }
}

export default AuthenticationManager;