import '../../style/clients-page.css';
import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
var createReactClass = require('create-react-class');
var React = require('react');

var ButtonEditCard = createReactClass({
    render: function () {
        return (
            <div class="static-cl-card-right-btn-div">
                <button onClick={this.props.onClick} type="button"
                    className={"btn btn-secondary fixed-width"}>
                    Edit
                </button>
            </div>
        );
    }
});

export default ButtonEditCard;